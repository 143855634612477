import { gql } from "@apollo/client";

export const MUTATION_SEND_OTP = gql`
  mutation sendOTP($deviceId: String!) {
    sendOTP(deviceId: $deviceId)
  }
`;

export const MUTATION_VERIFY_OTP = gql`
  mutation verifyOTP($otp: String!) {
    verifyOTP(otp: $otp)
  }
`;

export const MUTATION_SEND_CSV_EMAIL = gql`
  mutation sendCSVEmail($data: SendCSVEmailRequest!) {
    sendCSVEmail(data: $data)
  }
`;
