import { useLazyQuery, useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Campaign,
  CampaignInvestorStatus,
  CampaignScheduleType,
  CampaignStatus,
} from "../../__generated__/graphql";
import { Form } from "../../components/form/form";
import { LoadingSpinner } from "../../components/loading/spinner";
import { Sidebar } from "../../components/sidebar/sidebar";
import { Headline } from "../../components/text/headline";
import { AppRoute } from "../../routes";
import { AuthService } from "../../services/auth.service";
import { CampaignFormMessages } from "./components/messages";
import { CampaignFormActions } from "./sections/action-form";
import { CampaignFormProfileSection } from "./sections/profile-form";
import { CampaignFormStatusSection } from "./sections/status-form";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";
import { GET_CAMPAIGN_BY_ID } from "../../graphql/queries/campaign";
import {
  ADD_CAMPAIGN,
  UPDATE_CAMPAIGN,
} from "../../graphql/mutations/campaign";
import CampaignFormCriteriaSection from "./sections/criteria-form";
import CampaignFormScheduleSection from "./sections/schedule-form";
import { toISOStringOrNull } from "../../utils/string.utils";

export function CampaignPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [campaign, setCampaign] = useState<Campaign>();

  const [getCampaign, { data, loading, error }] = useLazyQuery(
    GET_CAMPAIGN_BY_ID,
    { errorPolicy: "all", fetchPolicy: "no-cache" }
  );
  const [
    addCampaign,
    { data: submitData, loading: submitting, error: submitError },
  ] = useMutation(ADD_CAMPAIGN, { errorPolicy: "all" });
  const [
    updateCampaign,
    { data: updateData, loading: updating, error: updateError },
  ] = useMutation(UPDATE_CAMPAIGN, { errorPolicy: "all" });
  const [mode, setMode] = useState<"add" | "edit">("add");
  const [added, setAdded] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [formError, setFormError] = useState<string>("");

  useAuthAndErrorHandling(error);
  useAuthAndErrorHandling(submitError);
  useAuthAndErrorHandling(updateError);

  useEffect(() => {
    if (!AuthService.authenticate) {
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const _added = queryParams.get("added");
    const _updated = queryParams.get("updated");
    if (id) {
      getCampaign({ variables: { id } });
      setMode("edit");
    } else {
      setMode("add");
    }
    if (_added) {
      setAdded(true);
    }
    if (_updated) {
      setUpdated(true);
    }

    // Navigate back to property list when Escape key pressed
    const handleKeyNav = (event: KeyboardEvent) => {
      switch (event.key) {
        case "Escape":
          navigate(AppRoute.CampaignList);
          break;
      }
    };

    window.addEventListener("keydown", handleKeyNav);

    return () => {
      window.removeEventListener("keydown", handleKeyNav);
    };
  }, []);

  useEffect(() => {
    if (submitData && submitData.addCampaign && submitData.addCampaign.id) {
      navigate(
        `${AppRoute.CampaignList}?id=${submitData.addCampaign.id}&added=1`
      );
    }
  }, [submitData]);

  useEffect(() => {
    if (
      updateData &&
      updateData.updateCampaign &&
      updateData.updateCampaign.id
    ) {
      navigate(`${AppRoute.CampaignList}?updated=1`);
    }
  }, [updateData]);

  useEffect(() => {
    if (data && data.campaign) {
      setCampaign(data.campaign);
    }
  }, [data]);

  async function submit(e: FormEvent) {
    e.preventDefault();

    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);

    console.log("Form", {
      name: formData.get("name") as string,
      title: formData.get("title") as string,
      description: formData.get("description") as string,
      scheduleType: formData.get("scheduleType") as CampaignScheduleType,
      scheduleDate: (formData.get("scheduleDate") as string) || null,
      investorStatus: (formData.get("investorStatus") as string) || null,
      signupStart: (formData.get("signupStart") as string) || null,
      signupEnd: (formData.get("signupEnd") as string) || null,
      isActive: formData.get("isActive") as unknown as boolean,
    });

    // isActive is string with true or false values. Fix it
    const status = formData.get("status") as string;
    let isActive = false;

    if (status === "true") {
      isActive = true;
    }

    if (mode === "edit") {
      try {
        const response = await updateCampaign({
          variables: {
            id: campaign?.id,
            name: formData.get("name") as string,
            title: formData.get("title") as string,
            description: formData.get("description") as string,
            scheduleType: formData.get("scheduleType") as CampaignScheduleType,
            scheduleDate:
              new Date(formData.get("scheduleDate") as string).toISOString() ||
              null,
            investorStatus: (formData.get("investorStatus") as string) || null,
            signupStart: (formData.get("signupStart") as string) || null,
            signupEnd: (formData.get("signupEnd") as string) || null,
            isActive: isActive,
          },
        });

        if (response.errors?.length) {
          setFormError(response.errors[0].message);
        }
      } catch (e) {
        console.log("Error", e);
      }
      return;
    } else {
      try {
        const response = await addCampaign({
          variables: {
            name: formData.get("name") as string,
            title: formData.get("title") as string,
            description: formData.get("description") as string,
            scheduleType: formData.get("scheduleType") as CampaignScheduleType,
            scheduleDate: toISOStringOrNull(
              formData.get("scheduleDate") as string
            ),
            investorStatus: formData.get(
              "investorStatus"
            ) as CampaignInvestorStatus,
            signupStart: (formData.get("signupStart") as string) || null,
            signupEnd: (formData.get("signupEnd") as string) || null,
          },
        });

        if (response.errors?.length) {
          setFormError(response.errors[0].message);
        }
      } catch (e) {
        console.log("Error", e);
      }
    }
  }

  console.log("campaign", campaign);

  return (
    <>
      <Sidebar />

      <section className="w-full content">
        <Headline className="flex gap-lg">
          {mode === "add" ? "Add Campaign" : "Update Campaign"}
          {loading && <LoadingSpinner size={32} />}
        </Headline>

        <div className="flex flex-col md:flex-row gap">
          <Form
            className="flex-3"
            onSubmit={submit}
            disabled={
              submitting || updating || campaign?.status === CampaignStatus.Sent
            }
          >
            <CampaignFormMessages
              submitting={submitting}
              updating={updating}
              added={added}
              updated={updated}
              completed={campaign?.status === CampaignStatus.Sent}
              expired={campaign?.status === CampaignStatus.Expired}
              updateError={updateError}
              submitError={submitError}
            />

            <CampaignFormProfileSection campaign={campaign} mode={mode} />
            <CampaignFormCriteriaSection campaign={campaign} />
            <CampaignFormScheduleSection campaign={campaign} />
            <CampaignFormStatusSection campaign={campaign} mode={mode} />
            {formError && (
              <div className="px-12 py-6 mb-12 text-red-500 border rounded-lg bg-light-grey">
                {formError}
              </div>
            )}
            <CampaignFormActions mode={mode} />
          </Form>
        </div>
      </section>
    </>
  );
}
