import { gql } from "@apollo/client";

export const ADD_CAMPAIGN = gql`
  mutation AddCampaign(
    $name: String!
    $title: String!
    $description: String!
    $scheduleType: CampaignScheduleType!
    $scheduleDate: String
    $investorStatus: CampaignInvestorStatus!
    $signupStart: String
    $signupEnd: String
  ) {
    addCampaign(
      data: {
        name: $name
        title: $title
        description: $description
        scheduleType: $scheduleType
        scheduleDate: $scheduleDate
        investorStatus: $investorStatus
        signupStart: $signupStart
        signupEnd: $signupEnd
      }
    ) {
      id
      name
      description
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign(
    $id: String!
    $name: String!
    $title: String!
    $description: String!
    $scheduleType: CampaignScheduleType!
    $scheduleDate: String
    $investorStatus: CampaignInvestorStatus!
    $signupStart: String
    $signupEnd: String
    $isActive: Boolean!
  ) {
    updateCampaign(
      data: {
        id: $id
        name: $name
        title: $title
        description: $description
        scheduleType: $scheduleType
        scheduleDate: $scheduleDate
        investorStatus: $investorStatus
        signupStart: $signupStart
        signupEnd: $signupEnd
        isActive: $isActive
      }
    ) {
      id
      name
      description
    }
  }
`;

export const MARK_CAMPAIGN_AS_ACTIVE = gql`
  mutation MarkCampaignAsActive($id: String!) {
    markAsActive(id: $id) {
      id
      name
      description
    }
  }
`;
