import { useLazyQuery, useMutation } from "@apollo/client";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Property, RoleAction, RoleModule } from "../../__generated__/graphql";
import { LoadingPlaceholder } from "../../components/loading/placeholder";
import { Sidebar } from "../../components/sidebar/sidebar";
import { Tab } from "../../components/tab/tab";
import { QUERY_GET_PROPERTY_LIST } from "../../graphql/queries/get-property_list";
import { AppRoute } from "../../routes";
import { AuthService } from "../../services/auth.service";
import { toCurrency } from "../../utils/string.utils";
import { PropertyTypeModal } from "./components/property-types";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";
import { useContext } from "react";
import { NotificationContext } from "../../context/Notification";
import NotificationTopBar from "../../components/notification/notification-bar";
import { MUTATION_UPDATE_PROPERTIES_ORDER } from "../../graphql/mutations/property";
import { ConfirmModal } from "../../components/modal/ConfirmModal";

export function PropertyListPage() {
  const { turnNotificationsOn, hideNotificationBar } =
    useContext(NotificationContext);
  const tabs = useRef([
    {
      name: "Available",
      action: RoleAction.ListAvailable,
    },
    {
      name: "Coming Soon",
      action: RoleAction.ListAvailable,
    },
    {
      name: "New",
      action: RoleAction.ListNew,
    },
    {
      name: "Inactive",
      action: RoleAction.ListInactive,
    },
    {
      name: "Rented",
      action: RoleAction.ListRented,
    },
    {
      name: "Funded",
      action: RoleAction.ListCompleted,
    },
    {
      name: "Exited",
      action: RoleAction.ListExited,
    },
  ]);

  const navigate = useNavigate();

  const [getProperties, { loading, error, data }] = useLazyQuery(
    QUERY_GET_PROPERTY_LIST,
    { errorPolicy: "all", fetchPolicy: "no-cache" }
  );
  const [properties, setProperties] = useState<Property[]>([]);
  const [selectedTabItem, setSelectedTabItem] = useState("Available");
  const [selectedRow, setSelectedRow] = useState<number>();
  const [originalProperties, setOriginalProperties] = useState<Property[]>([]); // Store original properties
  const [isModalOpen, setIsModalOpen] = useState(false); // Control modal visibility
  const [sourceIndex, setSourceIndex] = useState<number | null>(null); // Store source index for drag
  const [targetIndex, setTargetIndex] = useState<number | null>(null); // Store target index for drop
  const [confirmationMessage, setConfirmationMessage] = useState<
    string | null
  >(); // Store message for confirmation
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  ); // Store error message
  const [showTypes, setShowTypes] = useState(false);

  const [updatePropertiesOrder, { loading: updating, error: updateError }] =
    useMutation(MUTATION_UPDATE_PROPERTIES_ORDER, { errorPolicy: "all" });

  const handleOpenTypes = () => {
    setShowTypes(true);
  };

  const handleCloseTypes = () => {
    setShowTypes(false);
  };

  useAuthAndErrorHandling(error);

  useEffect(() => {
    if (data && data.properties) {
      setProperties(
        data.properties
          .slice()
          .sort(
            (a: { order: number }, b: { order: number }) => a.order - b.order
          )
      );
    }
  }, [data]);

  const selectProperty = useCallback(
    (id: string) => {
      if (!AuthService.hasRole(RoleModule.Property, RoleAction.Update)) {
        return;
      }
      navigate(`${AppRoute.PropertyEdit}?id=${id}`);
    },
    [navigate]
  );

  useEffect(() => {
    if (!AuthService.authenticate(navigate)) {
      return;
    }

    getProperties({
      variables: {
        take: 50,
        skip: 0,
        where: { filters: getFiltersForTabItem(selectedTabItem) },
      },
    });
  }, []);

  // Navigate table using up, down and enter
  useEffect(() => {
    const handleKeyNav = (event: KeyboardEvent) => {
      if (!data) {
        return;
      }

      switch (event.key) {
        case "Enter":
          if (
            selectedRow !== undefined &&
            selectedRow >= 0 &&
            selectedRow < data.properties.length
          ) {
            selectProperty(data.properties[selectedRow].id);
          }
          break;
        case "ArrowUp":
          if (selectedRow !== undefined && selectedRow > 0) {
            setSelectedRow(selectedRow - 1);
          } else {
            setSelectedRow(data.properties.length - 1);
          }
          break;
        case "ArrowDown":
          if (
            selectedRow !== undefined &&
            selectedRow < data.properties.length - 1
          ) {
            setSelectedRow(selectedRow + 1);
          } else {
            setSelectedRow(0);
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyNav);

    return () => {
      window.removeEventListener("keydown", handleKeyNav);
    };
  }, [data, selectedRow, selectProperty]);

  useEffect(() => {
    getProperties({
      variables: {
        take: 50,
        skip: 0,
        where: { filters: getFiltersForTabItem(selectedTabItem) },
      },
    });
  }, [selectedTabItem]);

  function getTabList() {
    return tabs.current
      .filter((tab) => AuthService.hasRole(RoleModule.Property, tab.action))
      .map((tab) => tab.name);
  }

  function handleTabItemClick(tabItem: string) {
    setSelectedTabItem(tabItem);
  }

  function getFiltersForTabItem(tabItem: string) {
    switch (tabItem) {
      case "Available":
        return [{ field: "status", operator: "=", value: "active" }];
      case "New":
        return [{ field: "status", operator: "=", value: "pending" }];
      case "Inactive":
        return [{ field: "status", operator: "=", value: "inactive" }];
      case "Rented":
        return [{ field: "is_rented", operator: "=", value: "true" }];
      case "Funded":
        return [{ field: "status", operator: "=", value: "completed" }];
      case "Exited":
        return [{ field: "code", operator: "=", value: "XXXAAA" }];
      case "Coming Soon":
        return [{ field: "status", operator: "=", value: "coming_soon" }];
      default:
        return {};
    }
  }

  const handleDragStart = (
    e: React.DragEvent<HTMLTableRowElement>,
    index: number
  ) => {
    setOriginalProperties([...properties]); // Store a copy of the properties before the drag
    e.dataTransfer.setData("text/plain", index.toString());
    setSourceIndex(index); // Set source index when drag starts
  };

  // Handle drag over
  const handleDragOver = (e: React.DragEvent<HTMLTableRowElement>) => {
    e.preventDefault(); // Allow dropping by preventing the default behavior
  };

  console.log("properties", properties);

  const handleDrop = (
    e: React.DragEvent<HTMLTableRowElement>,
    targetIndex: number
  ) => {
    e.preventDefault();

    if (sourceIndex === null || targetIndex === sourceIndex) return;

    setTargetIndex(targetIndex); // Set the target index when drop occurs

    const propertyTitle = properties[sourceIndex]?.title || "Property"; // Fallback to 'Property' if title is undefined
    setConfirmationMessage(
      `Are you sure you want to move "${propertyTitle}" to position   ${targetIndex + 1}  ?`
    );

    setIsModalOpen(true);
  };

  // Handle the confirm action in the modal
  const handleConfirmReorder = async () => {
    if (sourceIndex === null || targetIndex === null) return;

    const reorderedProperties = [...properties];
    const [movedItem] = reorderedProperties.splice(sourceIndex, 1);
    reorderedProperties.splice(targetIndex, 0, movedItem);

    try {
      // Update the order and persist changes
      setProperties(reorderedProperties);
      await updatePropertiesOrder({
        variables: {
          properties: reorderedProperties.map((property, index) => ({
            id: property.id,
            order: index,
          })),
        },
      });

      // Close the modal after confirming
      setIsModalOpen(false);
    } catch (err) {
      console.error("Failed to save image order", err);
      setErrorMessage(
        "An error occurred while saving the new order. Please try again."
      );
      setIsModalOpen(false);
    }
  };

  // Handle the cancel action in the modal
  const handleCancelReorder = () => {
    setProperties([...originalProperties]); // Revert to the original order
    setIsModalOpen(false); // Close the modal
  };

  return (
    <>
      <Sidebar />

      <section className="mt-0 content full properties sm:mt-14">
        {!turnNotificationsOn && !hideNotificationBar && (
          <div className="">
            <NotificationTopBar />
          </div>
        )}
        <h1 className="mb-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Properties
        </h1>

        <div className="flex flex-col-reverse md:flex-row md:justify-between">
          <Tab
            items={getTabList()}
            selectedTabItem={selectedTabItem}
            loading={loading}
            handleTabItemClick={handleTabItemClick}
          />
          {AuthService.hasRole(RoleModule.Property, RoleAction.Add) && (
            <div className="flex h-auto gap-2 mb-2 mb-4 md:mb-0">
              <a
                href="#"
                className="flex items-center justify-center flex-grow w-full h-full px-2 transition-all duration-300 rounded-lg xl:gap-2 xl:px-4 mb:w-auto bg-pn-blue hover:bg-pn-dark-yellow"
                onClick={() => navigate(AppRoute.PropertyEdit)}
              >
                <PlusCircleIcon width={24} className="text-white" />
                <span className="self-center text-sm font-semibold text-center text-white">
                  Add Property
                </span>
              </a>
              <button
                className="flex-grow h-full btn btn-secondary"
                onClick={handleOpenTypes}
              >
                Types
              </button>
            </div>
          )}
          {showTypes && (
            <PropertyTypeModal show={showTypes} onShowHide={handleCloseTypes} />
          )}
        </div>

        <table className="w-full pt-2 bg-white border border-separate rounded-lg table-auto">
          <thead>
            <tr>
              <th className="px-4 py-3 border-b"></th>
              <th className="px-4 py-3 border-b">Property</th>
              <th className="hidden px-4 py-3 border-b md:table-cell">Price</th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Investment (#)
              </th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Investment (GBP)
              </th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Minimum Investment
              </th>
              <th className="hidden px-4 py-3 border-b md:table-cell">City</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              data &&
              properties &&
              properties.map((property: Property, index: number) => (
                <tr
                  key={index}
                  className={`hover:bg-pn-blue hover:text-white cursor-pointer transition-all duration-300 ${
                    selectedRow === index
                      ? "bg-pn-dark-yellow text-pn-blue"
                      : ""
                  }`}
                  draggable
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, index)}
                  onClick={() => selectProperty(property.id)}
                >
                  <td
                    className="px-4 py-3 text-center cursor-move"
                    onClick={(event) => event.stopPropagation()}
                  >
                    <div className="flex flex-col items-center gap-1 cursor-move">
                      <div className="flex gap-1">
                        <span className="w-1 h-1 bg-gray-400 rounded-full"></span>
                        <span className="w-1 h-1 bg-gray-400 rounded-full"></span>
                      </div>
                      <div className="flex gap-1">
                        <span className="w-1 h-1 bg-gray-400 rounded-full"></span>
                        <span className="w-1 h-1 bg-gray-400 rounded-full"></span>
                      </div>
                      <div className="flex gap-1">
                        <span className="w-1 h-1 bg-gray-400 rounded-full"></span>
                        <span className="w-1 h-1 bg-gray-400 rounded-full"></span>
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-3 text-sm">{property.title}</td>
                  <td className="hidden px-4 py-3 text-sm text-right md:table-cell">
                    {toCurrency(property.agreementPrice)}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {property.pledges.length}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {toCurrency(
                      property.pledges.reduce(
                        (sum, pledge) => Number(sum) + Number(pledge.amount),
                        0
                      )
                    )}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {toCurrency(property.minimumInvestment * 100 * 100)}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {property.city.name}
                  </td>
                </tr>
              ))}
            <ConfirmModal
              title="Confirm Reorder"
              isOpen={isModalOpen}
              onConfirm={handleConfirmReorder}
              onCancel={handleCancelReorder}
              errorMessage={errorMessage}
              confirmationMessage={confirmationMessage}
              warning
            />
            {loading && (
              <tr>
                <td colSpan={5} className="text-center">
                  <LoadingPlaceholder />
                </td>
              </tr>
            )}
            {!loading &&
              (!data || !data.properties || data.properties.length === 0) && (
                <tr>
                  <td colSpan={5} className="text-sm text-center">
                    <div className="flex flex-col items-center gap-2 p-5">
                      <InformationCircleIcon width={60} />
                      <p className="font-bold">
                        No {selectedTabItem} Properties
                      </p>
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </section>
    </>
  );
}
