import { useState, useEffect } from "react";
import { FormInput } from "../../../components/form/input";
import { FormSection } from "../../../components/form/section";
import {
  Campaign,
  CampaignScheduleType,
  CampaignStatus,
} from "../../../__generated__/graphql";
import { format } from "date-fns";

interface Props {
  campaign?: Campaign;
}

const formatDateTimeLocal = (isoString?: string) => {
  if (!isoString) return "";
  const date = new Date(isoString);
  return format(date, "yyyy-MM-dd'T'HH:mm"); // Converts to local time for display
};

const CampaignFormScheduleSection = ({ campaign }: Props) => {
  const [scheduleType, setScheduleType] = useState<CampaignScheduleType>(
    campaign?.scheduleType || CampaignScheduleType.Immediately
  );
  const [scheduledAt, setScheduledAt] = useState<string>(
    formatDateTimeLocal(campaign?.scheduledAt)
  );

  useEffect(() => {
    setScheduleType(campaign?.scheduleType || CampaignScheduleType.Immediately);
    setScheduledAt(formatDateTimeLocal(campaign?.scheduledAt)); // Display in local time
  }, [campaign]);

  console.log("campaign?.scheduledAt", campaign?.scheduledAt);
  console.log("scheduledAt", scheduledAt);

  return (
    <FormSection
      title="Campaign Scheduling"
      description="Choose when to send the campaign"
    >
      <div className="space-y-4">
        <label className="flex items-center space-x-2">
          <input
            type="radio"
            name="scheduleType"
            value={CampaignScheduleType.Immediately}
            checked={scheduleType === CampaignScheduleType.Immediately}
            onChange={() => setScheduleType(CampaignScheduleType.Immediately)}
            disabled={campaign?.status === CampaignStatus.Sent}
          />
          <span>Send Immediately</span>
        </label>
        <label className="flex items-center space-x-2">
          <input
            type="radio"
            name="scheduleType"
            value={CampaignScheduleType.Scheduled}
            checked={scheduleType === CampaignScheduleType.Scheduled}
            onChange={() => setScheduleType(CampaignScheduleType.Scheduled)}
            disabled={campaign?.status === CampaignStatus.Sent}
          />
          <span>Set Fixed Date & Time</span>
        </label>
        {scheduleType === CampaignScheduleType.Scheduled && (
          <FormInput
            label="Schedule Date & Time"
            name="scheduleDate"
            type="datetime-local"
            required
            defaultValue={scheduledAt}
            min={new Date().toISOString().slice(0, 16)}
            onChange={(e) => {
              const localDate = new Date((e.target as HTMLInputElement).value);
              setScheduledAt(localDate.toISOString());
            }}
            disabled={campaign?.status === CampaignStatus.Sent}
          />
        )}
      </div>
    </FormSection>
  );
};

export default CampaignFormScheduleSection;
