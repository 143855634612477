import { useCallback, useState } from "react";
import { Sidebar } from "../../components/sidebar/sidebar";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import { AuthService } from "../../services/auth.service";
import {
  Campaign,
  CampaignScheduleType,
  CampaignStatus,
  RoleAction,
  RoleModule,
} from "../../__generated__/graphql";
import { Link, useNavigate } from "react-router-dom";
import { AppRoute } from "../../routes";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import { LoadingPlaceholder } from "../../components/loading/placeholder";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_CAMPAIGNS } from "../../graphql/queries/campaign";
import { FormButton } from "../../components/form/button";
import { toDateTimeWithYearStartingAMPM } from "../../utils/string.utils";
import { MARK_CAMPAIGN_AS_ACTIVE } from "../../graphql/mutations/campaign";
import { Modal } from "../../components/modal/modal";

const CampaignListPage = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentAction, setCurrentAction] = useState<(() => void) | null>(null);

  const navigate = useNavigate();

  const {
    data: campaignsData,
    loading: loadingCampaigns,
    error: getCampaignError,
    refetch: refetchCampaigns,
  } = useQuery<{
    campaigns: Campaign[];
  }>(GET_ALL_CAMPAIGNS, { fetchPolicy: "network-only" });

  const [markAsActive, { loading: markAsActiveLoading }] = useMutation(
    MARK_CAMPAIGN_AS_ACTIVE,
    { errorPolicy: "all" }
  );

  const selectCampaign = useCallback(
    (id: string) => {
      if (!AuthService.hasRole(RoleModule.Campaign, RoleAction.Update)) {
        return;
      }
      navigate(`${AppRoute.Campaign}?id=${id}`);
    },
    [navigate]
  );

  const markActive = async (id: string) => {
    markAsActive({ variables: { id } });
  };

  const handleMarkActiveClick = (id: string) => {
    setCurrentAction(() => () => markActive(id));
    setShowConfirmation(true);
  };

  const handleConfirmation = () => {
    currentAction && currentAction();
    setShowConfirmation(false);
    refetchCampaigns();
  };

  return (
    <>
      <Sidebar />
      <section className="mt-0 content full properties sm:mt-14">
        <h1 className="mb-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Comapigns
        </h1>

        <div className="flex flex-col-reverse md:flex-row md:justify-end">
          {AuthService.hasRole(RoleModule.Campaign, RoleAction.Add) && (
            <div className="flex gap-2 mb-2 md:mb-0">
              <Link
                className="flex flex-wrap content-center justify-center w-full h-12 gap-2 px-4 py-2 transition-all duration-300 rounded-lg mb:w-auto bg-pn-blue hover:bg-pn-dark-yellow"
                to={AppRoute.Campaign}
              >
                <PlusCircleIcon width={24} className="text-white" />
                <span className="self-center text-sm font-semibold text-white">
                  Add Campaign
                </span>
              </Link>
            </div>
          )}
        </div>

        <table className="w-full pt-2 bg-white border border-separate rounded-lg table-auto">
          <thead>
            <tr>
              <th className="px-4 py-3 border-b">Name</th>
              <th className="hidden px-4 py-3 border-b md:table-cell">Title</th>
              <th className="hidden px-4 py-3 border-b md:table-cell">Type</th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Status
              </th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Schedule Time
              </th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Investors Type
              </th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {!loadingCampaigns &&
              campaignsData &&
              campaignsData.campaigns &&
              campaignsData.campaigns.map((campaign, index) => (
                <tr
                  key={index}
                  className={`hover:bg-pn-blue hover:text-white cursor-pointer transition-all duration-300`}
                  onClick={() => selectCampaign(campaign.id)}
                >
                  <td className="px-4 py-3 text-sm text-center text-bold">
                    {campaign.name}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {campaign.title}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {campaign.scheduleType}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {campaign.status}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {campaign.scheduledAt
                      ? toDateTimeWithYearStartingAMPM(campaign.scheduledAt)
                      : ""}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {campaign.investorStatus}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    <FormButton
                      title={
                        campaign.isActive
                          ? "Activated"
                          : campaign.scheduleType ===
                              CampaignScheduleType.Scheduled
                            ? "Make it Active"
                            : "Send Now"
                      }
                      size="auto"
                      onClick={(event) => {
                        (
                          event as React.MouseEvent<HTMLButtonElement>
                        ).stopPropagation();
                        handleMarkActiveClick(campaign.id);
                      }}
                      disabled={
                        markAsActiveLoading ||
                        campaign.isActive ||
                        campaign.status === CampaignStatus.Expired
                      }
                      loading={markAsActiveLoading}
                    />
                  </td>
                </tr>
              ))}
            {loadingCampaigns && (
              <tr>
                <td colSpan={5} className="text-center">
                  <LoadingPlaceholder />
                </td>
              </tr>
            )}
            {!loadingCampaigns &&
              (!campaignsData ||
                !campaignsData.campaigns ||
                campaignsData.campaigns.length === 0) && (
                <tr>
                  <td colSpan={7} className="text-sm text-center">
                    <div className="flex flex-col items-center gap-2 p-5">
                      <InformationCircleIcon width={60} />
                      <p className="font-bold">No Campaigns</p>
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </table>

        {showConfirmation && (
          <Modal
            title="Confirmation"
            onClose={() => setShowConfirmation(false)}
            onSubmit={(e) => {
              e.preventDefault();
              handleConfirmation();
            }}
            actionElement={
              <button
                type="submit"
                className="py-6 text-lg btn btn-primary hover:opacity-90"
                disabled={markAsActiveLoading}
              >
                Confirm
              </button>
            }
          >
            <p className="text-lg text-gray-600">
              Are you sure you want to mark this campaign as active?
            </p>
          </Modal>
        )}
      </section>
    </>
  );
};

export default CampaignListPage;
