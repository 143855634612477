import { useLazyQuery, useMutation } from "@apollo/client";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  RoleAction,
  RoleModule,
  User,
  UserStatus,
} from "../../__generated__/graphql";
import { FormButton } from "../../components/form/button";
import { LoadingPlaceholder } from "../../components/loading/placeholder";
import { Sidebar } from "../../components/sidebar/sidebar";
import { Tab } from "../../components/tab/tab";
import { MUTATION_SEND_UNVERIFIED_REMINDER_EMAIL } from "../../graphql/mutations/admin";
import { MUTATION_UPDATE_INVESTOR } from "../../graphql/mutations/investor";
import { MUTATION_SEND_CSV_EMAIL } from "../../graphql/mutations/user";
import {
  QUERY_GET_ALL_USER_LIST,
  QUERY_GET_PAGINATED_USER_LIST,
} from "../../graphql/queries/user";
import { GET_ALL_PLEDGES } from "../../graphql/queries/get-all-pledges";
import { PaginatedData } from "../../interfaces/pagination.interface";
import { AppRoute } from "../../routes";
import { AuthService } from "../../services/auth.service";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";
import {
  toCurrency,
  toCurrencyValue,
  toDateTimeWithYearStartingAMPM,
} from "../../utils/string.utils";
import { PaginationBar } from "../../components/pagination/pagination.component";
import { endOfMonth, formatRelative, startOfMonth } from "date-fns";
import Snackbar from "../../components/snackbar/snackbar";
import { Modal } from "../../components/modal/modal";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

interface UserTotals extends User {
  totalDeposits: number;
  totalInvestments: number;
}

interface UserPaginatedData<T> extends PaginatedData<T> {
  totalDeposits: number;
  totalInvestments: number;
}

interface Property {
  title: string;
}

interface PropertyPledge {
  amount: number;
  createdAt: string;
  updatedAt: string;
  property: Property;
  status: string;
}

export function InvestorListPage() {
  const tabs = useRef([
    {
      name: "All",
      action: RoleAction.List,
    },
    {
      name: "Verified",
      action: RoleAction.ListActive,
    },
    {
      name: "Unverified",
      action: RoleAction.ListUnverified,
    },
    {
      name: "Blocked",
      action: RoleAction.ListBlocked,
    },
    {
      name: "Has Balance",
      action: RoleAction.ListHighBalance,
    },
    {
      name: "Deposited",
      action: RoleAction.ListDeposited, // Add a new action for this filter
    },
    {
      name: "Invested",
      action: RoleAction.ListInvested, // Add a new action for this filter
    },
  ]);

  const navigate = useNavigate();

  const [getUsers, { loading, error, data }] = useLazyQuery<{
    users: UserPaginatedData<UserTotals>;
  }>(QUERY_GET_PAGINATED_USER_LIST, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  });
  const [
    getAllUsers,
    { loading: allUserLoading, error: allUserError, data: allUsers },
  ] = useLazyQuery<{ unPaginatedUsers: UserPaginatedData<UserTotals> }>(
    QUERY_GET_ALL_USER_LIST,
    { errorPolicy: "all", fetchPolicy: "no-cache" }
  );
  const [
    updateInvestor,
    { loading: updateLoading, error: updateError, data: updateData },
  ] = useMutation(MUTATION_UPDATE_INVESTOR, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  });
  const [
    sendUnverifiedReminderEmail,
    { loading: sendingReminderLoading, data: sendingReminderData },
  ] = useMutation(MUTATION_SEND_UNVERIFIED_REMINDER_EMAIL, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  });
  const [
    sendCSVEmail,
    {
      loading: sendingCSVLoading,
      error: sendingCSVError,
      data: sendingCSVData,
    },
  ] = useMutation(MUTATION_SEND_CSV_EMAIL, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  });
  const [selectedTabItem, setSelectedTabItem] = useState("All");
  const [selectedRow, setSelectedRow] = useState<number>();
  const [reminderEmailSentForInvestor, setReminderEmailSentForInvestor] =
    useState<string>();
  const today = new Date();
  const [paginationData, setPaginationData] = useState({
    take: 10,
    skip: 0,
    startDate: startOfMonth(today).toISOString(),
    endDate: endOfMonth(today).toISOString(),
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentAction, setCurrentAction] = useState<(() => void) | null>(null);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProperty, setSelectedProperty] = useState<string | null>(null);
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [getPropertyPledges, { loading: propertyLoading, data: propertyData }] =
    useLazyQuery(GET_ALL_PLEDGES, {
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    });

  useAuthAndErrorHandling(error);

  useEffect(() => {
    if (!AuthService.authenticate(navigate)) {
      return;
    }
    // Fetch users on page load with "This Month" default date filter
    getUsers({
      variables: {
        take: paginationData.take,
        skip: paginationData.skip,
        startDate: paginationData.startDate,
        endDate: paginationData.endDate,
        searchTerm: searchTerm,
        where: { filters: getFiltersForTabItem(selectedTabItem) },
      },
    });
  }, []);

  // Navigate table using up, down and enter
  useEffect(() => {
    const handleKeyNav = (event: KeyboardEvent) => {
      if (!data) {
        return;
      }

      switch (event.key) {
        case "Enter":
          if (
            selectedRow !== undefined &&
            selectedRow >= 0 &&
            selectedRow < data.users.items.length
          ) {
            selectInvestor(data.users.items[selectedRow].id);
          }
          break;
        case "ArrowUp":
          if (selectedRow !== undefined && selectedRow > 0) {
            setSelectedRow(selectedRow - 1);
          } else {
            setSelectedRow(data.users.items.length - 1);
          }
          break;
        case "ArrowDown":
          if (
            selectedRow !== undefined &&
            selectedRow < data.users.items.length - 1
          ) {
            setSelectedRow(selectedRow + 1);
          } else {
            setSelectedRow(0);
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyNav);

    return () => {
      window.removeEventListener("keydown", handleKeyNav);
    };
  }, [data, selectedRow, selectInvestor]);

  useEffect(() => {
    if (sendingReminderData) {
      setReminderEmailSentForInvestor(
        sendingReminderData.sendUnverifiedReminderEmail.id
      );
    }
  }, [sendingReminderData]);

  function handleTabItemClick(tabItem: string) {
    setSelectedTabItem(tabItem);
  }

  function getTabList() {
    return tabs.current
      .filter((tab) => AuthService.hasRole(RoleModule.Investor, tab.action))
      .map((tab) => tab.name);
  }

  // Fetch all pledges on page load to get active properties list
  useEffect(() => {
    getPropertyPledges({ variables: { take: 1000, skip: 0 } });
  }, []);

  // Filter the users when selectedProperty changes
  useEffect(() => {
    if (propertyData) {
      const selectedPledges = propertyData.getAllPropertyPledges.items.filter(
        (pledge: any) => pledge.property.title === selectedProperty
      );
      // Get users associated with the selected pledges
      const usersWithPledges = selectedPledges.map(
        (pledge: any) => pledge.user
      );

      // Remove duplicate users by user ID
      const uniqueUsers = usersWithPledges.reduce((acc: any[], user: any) => {
        if (!acc.some((u) => u.id === user.id)) {
          acc.push(user);
        }
        return acc;
      }, []);

      // Get complete user data by matching the filtered user IDs and exclude missing ones
      const fullUsersData = uniqueUsers
        .map((user: any) =>
          data?.users.items.find((u: any) => u.id === user.id)
        )
        .filter(Boolean); // Remove undefined values

      setFilteredUsers(fullUsersData);
    }
  }, [selectedProperty, propertyData, data]);

  // Log filteredUsers after it has been updated
  useEffect(() => {
    console.log(
      "filtered users updated from separate useEffect: ",
      filteredUsers
    );
  }, [filteredUsers]);

  function formatString(status: string): string {
    return status
      .split(/[_-]+/) // Split the string by underscores and hyphens
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter and make the rest lowercase
      .join(" "); // Join the words with a space
  }

  function getFiltersForTabItem(tabItem: string) {
    switch (tabItem) {
      case "All":
        return [{ field: "user_type", operator: "=", value: "investor" }];
      case "Verified":
        return [
          { field: "user_type", operator: "=", value: "investor" },
          { field: "status", operator: "=", value: "active" },
        ];
      case "Unverified":
        return [
          { field: "user_type", operator: "=", value: "investor" },
          { field: "status", operator: "=", value: "unverified" },
        ];
      case "Blocked":
        return [
          { field: "user_type", operator: "=", value: "investor" },
          { field: "status", operator: "=", value: "blocked" },
        ];
      case "Has Balance":
        return [
          { field: "user_type", olperator: "=", value: "investor" },
          { field: "investorProfile.balance", operator: ">", value: "0" },
        ];
      case "Deposited":
        return [
          { field: "user_type", operator: "=", value: "investor" },
          { field: "totalDeposits", operator: ">", value: "0" },
        ];
      case "Invested":
        return [
          { field: "user_type", operator: "=", value: "investor" },
          { field: "totalInvestments", operator: ">", value: "0" },
        ];
      default:
        return {};
    }
  }

  function blockOrUnblock(user: User) {
    if (user.status === UserStatus.Unverified) {
      return;
    }
    if (user.status === UserStatus.Blocked) {
      updateInvestor({ variables: { id: user.id, status: UserStatus.Active } });
    } else {
      updateInvestor({
        variables: { id: user.id, status: UserStatus.Blocked },
      });
    }
  }

  interface PropertyDropdownProps {
    properties: PropertyPledge[]; // Expecting an array of PropertyPledge
    onSelect: (value: string | null) => void; // Function to handle selection
    selectedProperty: string | null; // The selected property title
  }

  function PropertyDropdown({
    properties,
    onSelect,
    selectedProperty,
  }: PropertyDropdownProps) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const uniqueProperties = [
      "All Properties",
      ...Array.from(new Set(properties.map((p) => p.property.title))),
    ];

    // Close dropdown when clicking outside
    useEffect(() => {
      function handleClickOutside(event: { target: any }) {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <div className="relative w-full max-w-[280px]" ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full px-4 py-3 bg-white border border-gray-300 rounded-full flex justify-between items-center text-gray-700 shadow-sm hover:border-gray-400 whitespace-nowrap"
        >
          {selectedProperty || "Select a Property"}
          <ChevronDownIcon className="w-5 h-5 text-gray-500" />
        </button>

        {isOpen && (
          <ul className="absolute w-full bg-white border border-gray-300 shadow-lg rounded-lg mt-1 z-10">
            {uniqueProperties.map((title) => (
              <li
                key={title}
                className="px-6 py-2 cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  onSelect(title === "All Properties" ? null : title);
                  setIsOpen(false);
                }}
              >
                {title}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }

  const refresh = () => {
    handleDateChange(
      paginationData.take,
      paginationData.skip,
      paginationData.startDate,
      paginationData.endDate
    );
  };

  const handleBlockClick = (user: User) => {
    setCurrentAction(() => () => blockOrUnblock(user));
    setModalTitle(
      user.status === UserStatus.Blocked ? "Unblock Investor" : "Block Investor"
    );
    setModalDescription(
      `Are you sure you want to ${user.status === UserStatus.Blocked ? "unblock" : "block"} ${getFullName(user)}?`
    );
    setShowConfirmation(true);
  };

  const handleReminderClick = (user: User) => {
    setCurrentAction(() => () => sendReminder(user));
    setModalTitle("Send Reminder");
    setModalDescription(
      `Are you sure you want to send a reminder to ${getFullName(user)}?`
    );
    setShowConfirmation(true);
  };

  const handleConfirmation = () => {
    if (currentAction) {
      currentAction();
      setShowConfirmation(false);
      setCurrentAction(null);
      refresh();
    }
  };

  function sendReminder(user: User) {
    sendUnverifiedReminderEmail({ variables: { id: user.id } });
  }

  function selectInvestor(id: string) {
    navigate(`${AppRoute.Investor}?id=${id}`);
  }

  function getFullName(user: User) {
    return [user.firstName, user.lastName].join(" ");
  }

  const handleDateChange = (
    take: number,
    skip: number,
    startDate: string,
    endDate: string
  ) => {
    setPaginationData({
      take,
      skip,
      startDate,
      endDate,
    });

    // if (paginationData.startDate === '' && paginationData.endDate === '') {
    //   getUsers({ variables: { take, skip, where: { filters: getFiltersForTabItem(selectedTabItem) } } });
    // } else {
    getUsers({
      variables: {
        take,
        skip,
        startDate,
        endDate,
        searchTerm,
        where: { filters: getFiltersForTabItem(selectedTabItem) },
      },
    });
    // }
  };

  useEffect(() => {
    handleDateChange(
      paginationData.take,
      paginationData.skip,
      paginationData.startDate,
      paginationData.endDate
    );
    console.log("  ******************************  ");
    console.log("selectedTabItem: ", selectedTabItem);
    console.log("paginationData: ", paginationData);
    console.log("Data: ", data);
  }, [selectedTabItem]);

  const exportToCSV = async () => {
    try {
      const { data } = await getAllUsers({
        variables: {
          startDate: paginationData.startDate,
          endDate: paginationData.endDate,
          where: { filters: getFiltersForTabItem(selectedTabItem) },
        },
      });

      const formatDateTime = (dateString: string) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        return `${day}/${month}/${year} ${hours}:${minutes}`;
      };

      const formatPhoneNumber = (phone: string) => {
        const phoneNumber = parsePhoneNumberFromString(phone);
        if (phoneNumber && phoneNumber.isValid()) {
          return phoneNumber.formatInternational(); // Format in INTERNATIONAL format
        }
        return phone; // If invalid, return as-is
      };

      const users = data?.unPaginatedUsers.items || [];
      const csvData = users.map((user) => ({
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        phone: formatPhoneNumber(user.phone as string),
        status: user.status,
        balance: toCurrencyValue(user.investorProfile?.balance) || 0,
        deposits: toCurrencyValue(user.totalDeposits),
        investments: toCurrencyValue(user.totalInvestments),
        registered: formatDateTime(user.createdAt),
        dealStage: user.investorProfile?.hubSpotDealStatus,
      }));

      const csvContent = [
        [
          "Name",
          "Email",
          "Phone",
          "Status",
          "Balance (GBP)",
          "Deposits (GBP)",
          "Investments (GBP)",
          "Registered Date",
          "Deal Stage",
        ],
        ...csvData.map((row) => [
          row.name,
          row.email,
          row.phone,
          row.status,
          row.balance,
          row.deposits,
          row.investments,
          row.registered,
          row.dealStage,
        ]),
      ]
        .map((e) => e.join(","))
        .join("\n");

      const recipientEmail = AuthService.getAdminEmail() || "";

      // Send the CSV data to your backend to send via email
      await sendCSVViaEmail({
        csvContent,
        recipientEmail,
      });

      // Only show success message if email is sent successfully
      setSnackbarMessage("Investor data sent to your email!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error exporting CSV:", error);
      if (error instanceof Error) {
        setSnackbarMessage(error.message || "Failed to send CSV!"); // Show specific error message if available
      } else {
        setSnackbarMessage("Failed to send CSV!");
      }
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  // Function to send CSV data to your backend
  const sendCSVViaEmail = async ({
    csvContent,
    recipientEmail,
  }: {
    csvContent: string;
    recipientEmail: string;
  }) => {
    try {
      console.log(`Recipient Email: ${recipientEmail}`);
      const { data } = await sendCSVEmail({
        variables: {
          data: {
            csvContent,
            recipientEmail,
          },
        },
      });
      console.log("CSV email sent successfully:", data);
      setSnackbarMessage("CSV email sent successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error sending CSV email:", error);
      setSnackbarMessage("Failed to send CSV email!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSearchChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    getUsers({
      variables: {
        take: paginationData.take,
        skip: paginationData.skip,
        startDate: paginationData.startDate,
        endDate: paginationData.endDate,
        where: { filters: getFiltersForTabItem(selectedTabItem) },
        searchTerm: searchTerm,
      },
    });
  };
  // Check if the admin is eligible to export investors
  const canExportInvestors = AuthService.hasRole(
    RoleModule.Investor,
    RoleAction.Export
  );
  console.log("users", data?.users.items);

  const displayedUsers = data?.users.items;

  return (
    <>
      <Sidebar />

      <section className="content full properties">
        <h1 className="mb-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Investors
        </h1>

        <div className="flex flex-col-reverse items-center md:flex-row md:justify-between">
          <Tab
            items={getTabList()}
            selectedTabItem={selectedTabItem}
            loading={loading}
            handleTabItemClick={handleTabItemClick}
          />
          <div className=" mb-4 mr-3 ml-3">
            <PropertyDropdown
              properties={propertyData?.getAllPropertyPledges.items || []}
              onSelect={setSelectedProperty}
              selectedProperty={selectedProperty}
            />
          </div>
          {canExportInvestors && (
            <button
              className={`py-4 btn btn-secondary hover:!bg-pn-blue hover:!text-white disabled:cursor-not-allowed
      ${allUserLoading ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={exportToCSV}
              disabled={allUserLoading || data?.users.items.length === 0}
              style={{ marginTop: "-22px" }} // Moves button up
            >
              {allUserLoading ? "Exporting..." : "Export"}
            </button>
          )}
        </div>

        <PaginationBar
          take={10}
          total={data?.users?.total || 0}
          currentTotal={data?.users?.items.length || 0}
          onDateChange={handleDateChange}
          isSeachBox={true}
          onSearchChange={handleSearchChange}
          searchPlaceholder="Search Investors..."
          defaultRangeType="All"
        />

        <table className="w-full pt-2 bg-white border border-separate rounded-lg table-auto">
          <thead>
            <tr>
              <th className="px-4 py-3 border-b">Investor</th>
              {selectedTabItem === "All" ||
              selectedTabItem === "Has Balance" ? (
                <th className="px-4 py-3 border-b md:table-cell">
                  User Status
                </th>
              ) : null}
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Balance
              </th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Deposits
              </th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Investments
              </th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Registered on
              </th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Deal Stage
              </th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {!loading && filteredUsers && filteredUsers.length > 0 ? (
              filteredUsers.map((user: UserTotals, index: number) => (
                <tr
                  key={index}
                  className="transition-all duration-300 cursor-pointer hover:bg-pn-blue hover:text-white"
                  onClick={(event) => {
                    if (event.ctrlKey || event.metaKey) {
                      window.open(`/investor/${user.id}`, "_blank");
                    } else {
                      navigate(`/investor/${user.id}`);
                    }
                    console.log("user", user);
                  }}
                >
                  <td className="flex flex-col justify-center px-4 py-3 text-sm">
                    <div className="font-bold text-inherit">
                      {getFullName(user)}
                    </div>
                    <div className="text-[0.875rem] text-inherit">
                      {user.email}
                    </div>
                    <div className="text-[0.875rem] text-inherit">
                      {user.phone}
                    </div>
                  </td>
                  {(selectedTabItem === "All" ||
                    selectedTabItem === "Has Balance") && (
                    <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                      {user.status}
                    </td>
                  )}
                  <td className="hidden px-4 py-3 text-sm text-right md:table-cell">
                    {toCurrency(user.investorProfile?.balance)}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {toCurrency(user.totalDeposits)}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {toCurrency(user.totalInvestments)}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {toDateTimeWithYearStartingAMPM(user.createdAt)}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {formatString(
                      user.investorProfile?.hubSpotDealStatus || ""
                    )}
                  </td>
                  <td className="hidden px-4 py-3 text-sm md:table-cell">
                    {user.status === UserStatus.Active &&
                      AuthService.hasRole(
                        RoleModule.Investor,
                        RoleAction.Block
                      ) && (
                        <FormButton
                          title="Block"
                          size="xs"
                          onClick={(event) => {
                            (
                              event as React.MouseEvent<HTMLButtonElement>
                            ).stopPropagation();
                            handleBlockClick(user);
                          }}
                          disabled={updateLoading}
                          loading={updateLoading}
                        />
                      )}
                    {user.status === UserStatus.Blocked &&
                      AuthService.hasRole(
                        RoleModule.Investor,
                        RoleAction.Unblock
                      ) && (
                        <FormButton
                          title="Unblock"
                          size="xs"
                          onClick={(event) => {
                            (
                              event as React.MouseEvent<HTMLButtonElement>
                            ).stopPropagation();
                            handleBlockClick(user);
                          }}
                          disabled={updateLoading}
                          loading={updateLoading}
                        />
                      )}
                    {user.status === UserStatus.Unverified &&
                      AuthService.hasRole(
                        RoleModule.Investor,
                        RoleAction.UnverifiedReminderEmail
                      ) &&
                      reminderEmailSentForInvestor !== user.id && (
                        <FormButton
                          title="Send Reminder"
                          size="auto"
                          onClick={(event) => {
                            (
                              event as React.MouseEvent<HTMLButtonElement>
                            ).stopPropagation();
                            handleReminderClick(user);
                          }}
                          disabled={updateLoading}
                          loading={updateLoading}
                        />
                      )}
                    {user.status === UserStatus.Unverified &&
                      AuthService.hasRole(
                        RoleModule.Investor,
                        RoleAction.UnverifiedReminderEmail
                      ) &&
                      reminderEmailSentForInvestor === user.id && (
                        <FormButton
                          title="Email Sent!"
                          size="auto"
                          disabled={updateLoading}
                          loading={sendingReminderLoading}
                        />
                      )}
                  </td>
                </tr>
              ))
            ) : !loading && displayedUsers && displayedUsers.length > 0 ? (
              displayedUsers.map((user: UserTotals, index: number) => (
                <tr
                  key={index}
                  className="transition-all duration-300 cursor-pointer hover:bg-pn-blue hover:text-white"
                  onClick={(event) => {
                    if (event.ctrlKey || event.metaKey) {
                      window.open(`/investor/${user.id}`, "_blank");
                    } else {
                      navigate(`/investor/${user.id}`);
                    }
                    console.log("user", user);
                  }}
                >
                  <td className="flex flex-col justify-center px-4 py-3 text-sm">
                    <div className="font-bold text-inherit">
                      {getFullName(user)}
                    </div>
                    <div className="text-[0.875rem] text-inherit">
                      {user.email}
                    </div>
                    <div className="text-[0.875rem] text-inherit">
                      {user.phone}
                    </div>
                  </td>
                  {(selectedTabItem === "All" ||
                    selectedTabItem === "Has Balance") && (
                    <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                      {user.status}
                    </td>
                  )}
                  <td className="hidden px-4 py-3 text-sm text-right md:table-cell">
                    {toCurrency(user.investorProfile?.balance)}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {toCurrency(user.totalDeposits)}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {toCurrency(user.totalInvestments)}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {toDateTimeWithYearStartingAMPM(user.createdAt)}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {formatString(
                      user.investorProfile?.hubSpotDealStatus || ""
                    )}
                  </td>
                  <td className="hidden px-4 py-3 text-sm md:table-cell">
                    {user.status === UserStatus.Active &&
                      AuthService.hasRole(
                        RoleModule.Investor,
                        RoleAction.Block
                      ) && (
                        <FormButton
                          title="Block"
                          size="xs"
                          onClick={(event) => {
                            (
                              event as React.MouseEvent<HTMLButtonElement>
                            ).stopPropagation();
                            handleBlockClick(user);
                          }}
                          disabled={updateLoading}
                          loading={updateLoading}
                        />
                      )}
                    {user.status === UserStatus.Blocked &&
                      AuthService.hasRole(
                        RoleModule.Investor,
                        RoleAction.Unblock
                      ) && (
                        <FormButton
                          title="Unblock"
                          size="xs"
                          onClick={(event) => {
                            (
                              event as React.MouseEvent<HTMLButtonElement>
                            ).stopPropagation();
                            handleBlockClick(user);
                          }}
                          disabled={updateLoading}
                          loading={updateLoading}
                        />
                      )}
                    {user.status === UserStatus.Unverified &&
                      AuthService.hasRole(
                        RoleModule.Investor,
                        RoleAction.UnverifiedReminderEmail
                      ) &&
                      reminderEmailSentForInvestor !== user.id && (
                        <FormButton
                          title="Send Reminder"
                          size="auto"
                          onClick={(event) => {
                            (
                              event as React.MouseEvent<HTMLButtonElement>
                            ).stopPropagation();
                            handleReminderClick(user);
                          }}
                          disabled={updateLoading}
                          loading={updateLoading}
                        />
                      )}
                    {user.status === UserStatus.Unverified &&
                      AuthService.hasRole(
                        RoleModule.Investor,
                        RoleAction.UnverifiedReminderEmail
                      ) &&
                      reminderEmailSentForInvestor === user.id && (
                        <FormButton
                          title="Email Sent!"
                          size="auto"
                          disabled={updateLoading}
                          loading={sendingReminderLoading}
                        />
                      )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  <LoadingPlaceholder />
                </td>
              </tr>
            )}

            {!loading &&
              (!data || !data.users.items || data.users.items.length === 0) && (
                <tr>
                  <td colSpan={6} className="text-sm text-center">
                    <div className="flex flex-col items-center gap-2 p-5">
                      <InformationCircleIcon width={60} />
                      <p className="font-bold">
                        No {selectedTabItem} Investors
                      </p>
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </section>

      <Snackbar
        message={snackbarMessage}
        open={snackbarOpen}
        severity={snackbarSeverity}
        duration={3000}
        onClose={() => setSnackbarOpen(false)}
        position="bottom-right"
      />

      {showConfirmation && (
        <Modal
          title={modalTitle}
          onClose={() => setShowConfirmation(false)}
          onSubmit={(e) => {
            e.preventDefault();
            handleConfirmation();
          }}
          actionElement={
            <button
              type="submit"
              className="py-6 text-lg btn btn-primary hover:opacity-90"
              disabled={updateLoading}
            >
              Confirm
            </button>
          }
        >
          <p className="text-lg text-gray-600">{modalDescription}</p>
        </Modal>
      )}
    </>
  );
}
function formatPhoneNumber(arg0: string): any {
  throw new Error("Function not implemented.");
}

function formatDateTime(createdAt: any): any {
  throw new Error("Function not implemented.");
}
