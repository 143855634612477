import { gql } from "@apollo/client";

export const GET_ALL_CAMPAIGNS = gql`
  query GetAllCampaigns {
    campaigns {
      id
      name
      title
      scheduleType
      status
      scheduledAt
      isActive
      investorStatus
    }
  }
`;

export const GET_CAMPAIGN_BY_ID = gql`
  query GetCampaignById($id: String!) {
    campaign(id: $id) {
      id
      title
      name
      description
      scheduleType
      investorStatus
      isActive
      scheduledAt
      status
      filterStartDate
      filterEndDate
    }
  }
`;
