import { Campaign, CampaignStatus } from "../../../__generated__/graphql";
import { FormInput } from "../../../components/form/input";
import { FormSection } from "../../../components/form/section";

interface Props {
  campaign?: Campaign;
  mode: "add" | "edit";
}

export function CampaignFormProfileSection({ campaign, mode }: Props) {
  return (
    <FormSection
      title="Campaign"
      description="Basic details about the campaign"
    >
      <FormInput
        label="Name of Campaign"
        name="name"
        required
        placeholder="For internal use only"
        defaultValue={campaign?.name}
        disabled={campaign?.status === CampaignStatus.Sent}
      />
      <FormInput
        label="Title of Notification"
        name="title"
        required
        placeholder="Notification title"
        maxLength={50}
        defaultValue={campaign?.title}
        disabled={campaign?.status === CampaignStatus.Sent}
      />
      <FormInput
        label="Notification Message"
        name="description"
        required
        placeholder="Notification message"
        maxLength={120}
        defaultValue={campaign?.description}
        disabled={campaign?.status === CampaignStatus.Sent}
      />
    </FormSection>
  );
}
