import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  HubSpotContactLifeCycleStatus,
  InvestorLead,
  RoleAction,
  RoleModule,
} from "../../__generated__/graphql";
import { LoadingPlaceholder } from "../../components/loading/placeholder";
import { Sidebar } from "../../components/sidebar/sidebar";
import {
  QUERY_GET_ALL_LEAD_LIST,
  QUERY_GET_PAGINATED_LEAD_LIST,
} from "../../graphql/queries/leads";
import { AuthService } from "../../services/auth.service";
import { toDateForDisplay } from "../../utils/string.utils";
import { LinkGeneratorModal } from "../properties/components/link_generator_modal";
import { EmailModal } from "./email-modal";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";
import { MUTATION_SOFT_DELETE_LEAD } from "../../graphql/mutations/lead";
import { PaginationBar } from "../../components/pagination/pagination.component";
import { PaginatedData } from "../../interfaces/pagination.interface";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import { endOfMonth, startOfMonth } from "date-fns";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { Tab } from "../../components/tab/tab";

export function LeadListPage() {
  const tabs = useRef([
    {
      name: "Lead",
      action: RoleAction.ListLead,
      value: HubSpotContactLifeCycleStatus.Lead,
    },
    {
      name: "Subscriber",
      action: RoleAction.ListSubscriber,
      value: HubSpotContactLifeCycleStatus.Subscriber,
    },
    {
      name: "Marketing Qualified",
      action: RoleAction.ListMarketingQualifiedLead,
      value: HubSpotContactLifeCycleStatus.MarketingQualifiedLead,
    },
    {
      name: "Sales Qualified",
      action: RoleAction.ListSalesQualifiedLead,
      value: HubSpotContactLifeCycleStatus.SalesQualifiedLead,
    },
    {
      name: "Opportunity",
      action: RoleAction.ListOpportunity,
      value: HubSpotContactLifeCycleStatus.Opportunity,
    },
    {
      name: "Customer",
      action: RoleAction.ListCustomer,
      value: HubSpotContactLifeCycleStatus.Customer,
    },
    {
      name: "Evangelist",
      action: RoleAction.ListEvangelist,
      value: HubSpotContactLifeCycleStatus.Evangelist,
    },
    {
      name: "Other",
      action: RoleAction.ListOther,
      value: HubSpotContactLifeCycleStatus.Other,
    },
  ]);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedTabItem, setSelectedTabItem] = useState("Lead");
  const [hubSpotLifecycleStage, setHubSpotLifecycleStage] =
    useState<HubSpotContactLifeCycleStatus>(HubSpotContactLifeCycleStatus.Lead);

  //const [getLeads, { loading, error, data }] = useLazyQuery(QUERY_GET_LEAD_LIST, { errorPolicy: 'all', fetchPolicy: 'no-cache' });
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
  const [selectedConversationId, setSelectedConversationId] = useState<
    string | null
  >(null);
  const [allLeads, setAllLeads] = useState<InvestorLead[]>([]);
  const [filteredLeads, setFilteredLeads] = useState<InvestorLead[]>([]);
  const [getLeads, { loading, error, data }] = useLazyQuery<{
    getInterestedInvestors: PaginatedData<InvestorLead>;
  }>(QUERY_GET_PAGINATED_LEAD_LIST, {
    fetchPolicy: "no-cache",
  });
  const today = new Date();
  const [paginationData, setPaginationData] = useState({
    take: 10,
    skip: 0,
    startDate: startOfMonth(today).toISOString(),
    endDate: endOfMonth(today).toISOString(),
  });
  const [
    getAllLeads,
    { loading: loadingAllLeads, error: errorAllLeads, data: dataAllLeads },
  ] = useLazyQuery(QUERY_GET_ALL_LEAD_LIST, {
    fetchPolicy: "no-cache",
  });

  const [
    deleteLead,
    {
      data: deleteLeadData,
      loading: deleteLeadLoading,
      error: deleteLeadError,
    },
  ] = useMutation(MUTATION_SOFT_DELETE_LEAD);

  useAuthAndErrorHandling(error);

  useEffect(() => {
    if (
      data &&
      data.getInterestedInvestors &&
      data.getInterestedInvestors.items
    ) {
      setAllLeads(data.getInterestedInvestors.items);
      setFilteredLeads(data.getInterestedInvestors.items); // Initially show all leads
    }
  }, [data]);

  // Function to handle date change from DateFilter component
  const handleDateChange = (
    take: number,
    skip: number,
    startDate: string,
    endDate: string
  ) => {
    setPaginationData({ take, skip, startDate, endDate });

    getLeads({
      variables: {
        take,
        skip,
        startDate,
        endDate,
        hubSpotLifecycleStage: hubSpotLifecycleStage,
      },
    });
  };

  useEffect(() => {
    if (!AuthService.authenticate(navigate)) {
      return;
    }
  }, []);

  useEffect(() => {
    if (deleteLeadData) {
      handleDateChange(
        paginationData.take,
        paginationData.skip,
        paginationData.startDate,
        paginationData.endDate
      );
    }
  }, [deleteLeadData]);

  console.log("data", data);

  useEffect(() => {
    if (dataAllLeads && dataAllLeads.getInterestedInvestors) {
      exportToCSV();
    }
  }, [dataAllLeads]);

  const handleChatClick = (email: string, convId: string) => {
    setSelectedEmail(email);
    setShowEmailModal(true);
    if (convId) {
      setSelectedConversationId(convId);
    }
  };
  const handleDeleteClick = (email: string) => {
    if (email) {
      // Delete the lead
      deleteLead({ variables: { email } });
    }
  };

  const handleEmailSent = () => {
    getLeads();
  };

  function getTabList() {
    return tabs.current
      .filter((tab) => AuthService.hasRole(RoleModule.Leads, tab.action))
      .map((tab) => tab.name);
  }

  function handleTabItemClick(tabItem: string) {
    setSelectedTabItem(tabItem);
    const selectedTab = tabs.current.find((tab) => tab.name === tabItem);

    if (selectedTab) {
      setSelectedTabItem(tabItem); // Update the selected tab state
      setHubSpotLifecycleStage(selectedTab.value); // Update the hubSpotLifecycleStage

      // Call getLeads with the updated hubSpotLifecycleStage and current paginationData
      getLeads({
        variables: {
          take: paginationData.take,
          skip: paginationData.skip,
          startDate: paginationData.startDate,
          endDate: paginationData.endDate,
          hubSpotLifecycleStage: selectedTab.value, // Use the selected tab's lifecycle stage
        },
      });
    }
  }

  const exportToCSV = async () => {
    console.log("Fetching all leads for CSV export");

    // Use the defined paginationData's startDate and endDate to fetch all leads
    const { startDate, endDate } = paginationData;

    // Fetch all leads from the server
    const { data: allLeadsData } = await getAllLeads({
      variables: {
        startDate: startDate,
        endDate: endDate,
      },
    });

    // Check if leads were successfully fetched
    if (
      !allLeadsData ||
      !allLeadsData.getUnPaginatedInterestedInvestors ||
      !allLeadsData.getUnPaginatedInterestedInvestors.items
    ) {
      console.log("No leads data available for export");
      return;
    }

    const leads = allLeadsData.getUnPaginatedInterestedInvestors.items;

    // Define the CSV headers
    const csvHeaders = [
      "Name",
      "Email",
      "Phone Number",
      "Added on",
      "Referrer ID",
      "Campaign",
      "Medium",
      "Source",
      "Content",
      "Stage",
      "Term",
    ];

    const formatPhoneNumber = (phone: string) => {
      // Parse the phone number using libphonenumber-js
      const phoneNumber = parsePhoneNumberFromString(phone);
      if (phoneNumber && phoneNumber.isValid()) {
        return phoneNumber.formatInternational(); // Format in INTERNATIONAL format
      }
      return phone; // If invalid, return as-is
    };

    // Map over leads and create rows
    const csvRows = leads.map((lead: InvestorLead) => [
      lead.fullName || "", // Handle potential null/undefined values
      lead.email || "",
      formatPhoneNumber(lead.phoneNumber || ""),
      new Date(lead.createdAt).toISOString().split("T")[0], // Format date as YYYY-MM-DD
      lead.utm_id || "",
      lead.utm_campaign || "",
      lead.utm_medium || "",
      lead.utm_source || "",
      lead.utm_content || "",
      lead.utm_term || "",
      lead.hubSpotLifecycleStage || "",
    ]);

    // Combine headers and rows to create the CSV content
    const csvContent = [
      csvHeaders.join(","), // Headers
      ...csvRows.map((row: any) => row.join(",")), // Data rows
    ].join("\n");

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    // Set the download attribute for the link
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `leads-${new Date().toLocaleDateString("en-US").replace(/\//g, "-")}.csv`
    );
    link.style.visibility = "hidden";

    // Append link to the document, trigger the download, and remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Check if the admin is eligible to export Leads
  const canExportLeads = AuthService.hasRole(
    RoleModule.Leads,
    RoleAction.Export
  );

  return (
    <>
      <Sidebar />

      <section className="content full properties">
        <h1 className="mb-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Leads
        </h1>
        <div className="flex justify-between items-center mb-2">
          <div className="flex flex-wrap">
            <Tab
              items={getTabList()}
              selectedTabItem={selectedTabItem}
              loading={loading}
              handleTabItemClick={handleTabItemClick}
            />
          </div>
          <div className=" ml-4 mb-4 flex">
            <button
              className="py-4 mr-2 btn btn-secondary hover:!bg-pn-blue hover:!text-white"
              onClick={() => setShowModal(true)}
            >
              Generate Link
            </button>
            {canExportLeads && (
              <button
                className="py-4 btn btn-secondary hover:!bg-pn-blue hover:!text-white disabled:!cursor-not-allowed"
                disabled={
                  loadingAllLeads ||
                  data?.getInterestedInvestors.items.length === 0
                }
                onClick={exportToCSV}
              >
                {!loadingAllLeads ? "Export" : "Exporting..."}
              </button>
            )}
          </div>
        </div>
        {showModal && (
          <LinkGeneratorModal show={showModal} onShowHide={setShowModal} />
        )}

        <PaginationBar
          take={10}
          total={data?.getInterestedInvestors.total || 0}
          currentTotal={data?.getInterestedInvestors?.items.length || 0}
          onDateChange={handleDateChange}
          defaultRangeType="Today"
        />

        <table className="w-full pt-2 bg-white border border-separate rounded-lg table-auto">
          <thead>
            <tr>
              <th className="px-4 py-3 border-b">Person</th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Added on
              </th>
              <th className="hidden px-4 py-3 border-b md:table-cell">Stage</th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Referrer ID
              </th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Campaign
              </th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Medium
              </th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Source
              </th>
              <th className="hidden px-4 py-3 border-b md:table-cell">
                Content
              </th>
              <th className="hidden px-4 py-3 border-b md:table-cell">Term</th>
              <th className="px-4 py-3 border-b">Chat</th>
              <th className="px-4 py-3 border-b">Delete</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              data &&
              data.getInterestedInvestors &&
              filteredLeads.map((lead: InvestorLead, index: number) => (
                <tr
                  key={index}
                  className="transition-all duration-300 cursor-pointer hover:bg-pn-blue hover:!text-white"
                >
                  <td className="flex flex-col px-4 py-3 text-sm">
                    <div className="font-bold text-inherit">
                      {lead.fullName}
                    </div>
                    <div className="text-[0.875rem] text-inherit">
                      {lead.email}
                    </div>
                    <div className="text-[0.875rem] text-inherit">
                      {lead.phoneNumber}
                    </div>
                  </td>
                  <td className="hidden px-4 py-3 text-sm md:table-cell">
                    {toDateForDisplay(lead.createdAt)}
                  </td>
                  <td className="hidden px-4 py-3 text-sm capitalize md:table-cell">
                    {lead.hubSpotLifecycleStage}
                  </td>
                  <td className="hidden px-4 py-3 text-sm md:table-cell">
                    {lead.utm_id}
                  </td>
                  <td className="hidden px-4 py-3 text-sm md:table-cell">
                    {lead.utm_campaign}
                  </td>
                  <td className="hidden px-4 py-3 text-sm md:table-cell">
                    {lead.utm_medium}
                  </td>
                  <td className="hidden px-4 py-3 text-sm md:table-cell">
                    {lead.utm_source}
                  </td>
                  <td className="hidden px-4 py-3 text-sm md:table-cell">
                    {lead.utm_content}
                  </td>
                  <td className="hidden px-4 py-3 text-sm md:table-cell">
                    {lead.utm_term}
                  </td>
                  <td className="px-4 py-3 text-sm">
                    <button
                      className="text-3xl"
                      onClick={() =>
                        handleChatClick(
                          lead.email,
                          lead.intercomLeadConversationId!
                        )
                      }
                    >
                      <i className="lni lni-bubble"></i>
                    </button>
                  </td>
                  <td className="px-4 py-3 text-sm">
                    <button
                      className="btn "
                      onClick={() => handleDeleteClick(lead.email)}
                      disabled={deleteLeadLoading}
                    >
                      {deleteLeadLoading ? "Deleting" : "Delete"}
                    </button>
                  </td>
                </tr>
              ))}
            {loading && (
              <tr>
                <td colSpan={9} className="text-center">
                  <LoadingPlaceholder />
                </td>
              </tr>
            )}
            {!loading &&
              (!data ||
                !data.getInterestedInvestors ||
                data.getInterestedInvestors.items.length === 0) && (
                <tr>
                  <td colSpan={11} className="text-sm text-center">
                    <div className="flex flex-col items-center gap-2 p-5">
                      <InformationCircleIcon width={60} />
                      <p className="font-bold">No Leads</p>
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </table>

        {selectedEmail && (
          <EmailModal
            conversationId={selectedConversationId}
            email={selectedEmail}
            show={showEmailModal}
            onShowHide={setShowEmailModal}
            onEmailSent={handleEmailSent} // Pass the callback function
          />
        )}
      </section>
    </>
  );
}
