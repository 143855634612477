import { FormEvent, PropsWithChildren, useEffect } from "react";
import { CloseButton } from "../form/close";
import WarningImg from "../../assets/svgs/warning-error.svg";
import { FormButton } from "../form/button";

interface Props extends PropsWithChildren {
  title: string;
  errorMessage?: string;
  onConfirm?: () => void;
  onClose?: () => void;
  isSubmitting?: boolean;
  className?: string;
}

export function ConfirmDelete({
  title,
  onClose,
  onConfirm,
  errorMessage,
  isSubmitting,
  className,
}: Props) {
  // Close window on Esc key press
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose && onClose();
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <>
      <div
        id="default-modal"
        tabIndex={-1}
        aria-hidden="true"
        className={`fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto ${className}`}
      >
        <div className="relative w-full p-0 bg-white max-w-xl max-h-full rounded-lg shadow">
          <div className="flex items-center justify-between p-4 border-b rounded-md md:p-5">
            <div className="flex gap-2">
              <img src={WarningImg} width={20} alt="Warning" />
              <h3 className="text-xl font-semibold text-gray-900">{title}</h3>
            </div>
            <CloseButton onClick={() => onClose && onClose()} />
          </div>
          <div className="p-4 space-y-4 md:p-5">
            <div className="space-y-4">
              <p data-test="confirmation-message">
                Are you sure you want to delete this financial?
              </p>
              <div className="flex justify-end space-x-4">
                <FormButton
                  title="Cancel"
                  type="button"
                  color="white"
                  onClick={onClose}
                />
                <FormButton
                  title={isSubmitting ? "Deleting..." : "Delete"}
                  disabled={isSubmitting}
                  type="button"
                  color="red"
                  onClick={onConfirm}
                />
              </div>
            </div>
          </div>
          {errorMessage && <p className="text-red-500 p-4">{errorMessage}</p>}
        </div>
      </div>
      <div
        className="fixed inset-0 z-40 bg-gray-900/50 m-0"
        onClick={() => onClose && onClose()}
      ></div>
    </>
  );
}
