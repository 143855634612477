import { gql } from "@apollo/client";

export const GET_ALL_PLEDGES = gql`
  query getAllPropertyPledges($take: Int, $skip: Int) {
    getAllPropertyPledges(take: $take, skip: $skip) {
      items {
        amount
        createdAt
        updatedAt
        status
        property {
          id
          title
        }
        user {
          id
        }
      }
      total
    }
  }
`;
