import { useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import { Property, PropertyFinancials } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { FormInput } from "../../../components/form/input";
import { Modal } from "../../../components/modal/modal";
import {
  MUTATION_ADD_FINANCIAL,
  MUTATION_DELETE_FINANCIAL,
  MUTATION_UPDATE_FINANCIAL,
} from "../../../graphql/mutations/finanicial";
import {
  toCurrencyValue,
  toDateForInput,
  toCurrencyValueDecimal,
} from "../../../utils/string.utils";
import { FormCheck } from "../../../components/form/check";
import { ConfirmModal } from "../../../components/modal/ConfirmModal";
import { AppRoute } from "../../../routes";
import { useNavigate } from "react-router-dom";
import { ConfirmDelete } from "../../../components/modal/ConfirmDelete";

interface Props {
  property?: Property;
  financial?: PropertyFinancials;
  show?: boolean;
  onShowHide?: (show: boolean) => void;
}

export function PropertyFinancialModal({
  property,
  financial,
  show,
  onShowHide,
}: Props) {
  const navigate = useNavigate();

  const [addFinancial, { data: addData, loading: adding, error: addError }] =
    useMutation(MUTATION_ADD_FINANCIAL, { errorPolicy: "all" });
  const [
    updateFinancial,
    { data: updateData, loading: updating, error: updateError },
  ] = useMutation(MUTATION_UPDATE_FINANCIAL, { errorPolicy: "all" });
  const [
    deleteFinancial,
    { data: deleteData, loading: deleting, error: deleteError },
  ] = useMutation(MUTATION_DELETE_FINANCIAL, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
  });

  const [mode, setMode] = useState<"add" | "update">("add");
  const [financials, setFinancials] = useState<PropertyFinancials[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>();

  useEffect(() => {
    if (property && property.financials) {
      setFinancials(property.financials);
    } else {
      setFinancials([]);
    }
  }, [property]);

  useEffect(() => {
    if (addData || updateData) {
      onShowHide && onShowHide(false);
    }
  }, [addData, updateData]);

  useEffect(() => {
    if (addError || updateError) {
      setErrorMessage(addError?.message || updateError?.message);
    }
  }, [addError, updateError]);

  useEffect(() => {
    if (financial) {
      setMode("update");
    } else {
      // Reset data when in "add" mode
      setMode("add");
    }
  }, [financial]);

  function parseToFinancialValue(input: FormDataEntryValue | null): number {
    return Math.floor(parseFloat(input as string) * 100);
  }

  const validateFinancials = (
    data: Partial<PropertyFinancials> & { propertyId: string }
  ): string | undefined => {
    const validations = [
      {
        name: "Annual ROI (%)",
        value: data.projectedAnnualRoIPercentage / 100,
        min: 0,
        max: 100,
      },
      {
        name: "2 Year ROI (%)",
        value: data.year2ProjectedAnnualRoIPercentage / 100,
        min: 0,
        max: 100,
      },
      {
        name: "3 Year ROI (%)",
        value: data.year3ProjectedAnnualRoIPercentage / 100,
        min: 0,
        max: 100,
      },
      {
        name: "Annual ROI (£)",
        value: data.projectedAnnualRoIValue / 100,
        min: 0,
      },
      {
        name: "2 Year ROI (£)",
        value: data.year2ProjectedAnnualRoIValue / 100,
        min: 0,
      },
      {
        name: "3 Year ROI (£)",
        value: data.year3ProjectedAnnualRoIValue / 100,
        min: 0,
      },
      { name: "Annual Rent (£)", value: data.annualRent / 100, min: 0 },
    ];

    for (const validation of validations) {
      if (validation.value !== undefined && validation.value < validation.min) {
        return `${validation.name} cannot be less than ${validation.min}`;
      }
      if (
        validation.max !== undefined &&
        validation.value !== undefined &&
        validation.value > validation.max
      ) {
        return `${validation.name} cannot be more than ${validation.max}`;
      }
    }

    return undefined; // All validations passed
  };

  function getActionElement() {
    return (
      <div className="flex gap content-end gap-2">
        {!financial?.isMandatory && mode === "update" && (
          <FormButton
            title="Delete Financial"
            type="button"
            color="red"
            onClick={() => setShowConfirmModal(true)}
          />
        )}
        <FormButton
          title="Cancel"
          type="button"
          color="white"
          onClick={close}
        />
        <FormButton
          title={adding || updating ? "Saving..." : "Save"}
          type="submit"
          loading={adding || updating}
          disabled={adding || updating}
        />
      </div>
    );
  }

  const handleConfirmDelete = async () => {
    deleteFinancial({ variables: { id: financial?.id } });
    onShowHide && onShowHide(false);
    setShowConfirmModal(false);
  };

  const handleCancelDelete = () => {
    setShowConfirmModal(false);
  };

  function close() {
    onShowHide && onShowHide(false);
  }

  function submit(e: FormEvent) {
    e.preventDefault();

    if (!property) {
      return;
    }

    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const data: Partial<PropertyFinancials> & { propertyId: string } = {
      propertyId: property?.id,
      projectedAnnualRoIPercentage: parseToFinancialValue(
        formData.get("projectedAnnualRoIPercentage")
      ),
      projectedAnnualRoIValue: parseToFinancialValue(
        formData.get("projectedAnnualRoIValue")
      ),
      year2ProjectedAnnualRoIPercentage: parseToFinancialValue(
        formData.get("year2ProjectedAnnualRoIPercentage")
      ),
      year2ProjectedAnnualRoIValue: parseToFinancialValue(
        formData.get("year2ProjectedAnnualRoIValue")
      ),
      year3ProjectedAnnualRoIPercentage: parseToFinancialValue(
        formData.get("year3ProjectedAnnualRoIPercentage")
      ),
      year3ProjectedAnnualRoIValue: parseToFinancialValue(
        formData.get("year3ProjectedAnnualRoIValue")
      ),
      annualRent: parseToFinancialValue(formData.get("annualRent")),
      effectiveDate: formData.get("effectiveDate") as string,
      isMandatory: !!formData.get("isMandatory"),
    };

    const validationError = validateFinancials(data);
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    if (mode === "update" && financial) {
      data.id = financial.id;
      updateFinancial({ variables: data });
    } else {
      console.log(data);
      addFinancial({ variables: data });
    }

    setErrorMessage(undefined);
  }

  return show ? (
    <Modal
      title={(mode === "add" ? "Add" : "Update") + " Property Financial"}
      onClose={close}
      actionElement={getActionElement()}
      onSubmit={submit}
      errorMessage={errorMessage}
    >
      {show && showConfirmModal && (
        <ConfirmDelete
          title="Confirm Delete"
          onConfirm={handleConfirmDelete}
          onClose={handleCancelDelete}
          errorMessage={deleteError?.message}
          isSubmitting={deleting}
          className="max-w-xl justify-self-center"
        />
      )}
      <div className="grid grid-cols-2 gap-4">
        <FormInput
          name="projectedAnnualRoIPercentage"
          label="Annual ROI (%)"
          type="number"
          min={0}
          max={100}
          step={0.01}
          required
          defaultValue={toCurrencyValueDecimal(
            financial?.projectedAnnualRoIPercentage
          )}
        />
        <FormInput
          name="projectedAnnualRoIValue"
          label="Annual ROI (£)"
          type="number"
          isCurrency
          min={0}
          required
          defaultValue={toCurrencyValue(financial?.projectedAnnualRoIValue)}
        />
        <FormInput
          name="year2ProjectedAnnualRoIPercentage"
          label="2 Year ROI (%)"
          type="number"
          min={0}
          max={100}
          step={0.01}
          required
          defaultValue={toCurrencyValueDecimal(
            financial?.year2ProjectedAnnualRoIPercentage
          )}
        />
        <FormInput
          name="year2ProjectedAnnualRoIValue"
          label="2 Year ROI (£)"
          type="number"
          isCurrency
          min={0}
          required
          defaultValue={toCurrencyValue(
            financial?.year2ProjectedAnnualRoIValue
          )}
        />
        <FormInput
          name="year3ProjectedAnnualRoIPercentage"
          label="3 Year ROI (%)"
          type="number"
          min={0}
          max={100}
          step={0.01}
          required
          defaultValue={toCurrencyValueDecimal(
            financial?.year3ProjectedAnnualRoIPercentage
          )}
        />
        <FormInput
          name="year3ProjectedAnnualRoIValue"
          label="3 Year ROI (£)"
          type="number"
          isCurrency
          min={0}
          required
          defaultValue={toCurrencyValue(
            financial?.year3ProjectedAnnualRoIValue
          )}
        />
        <FormInput
          name="annualRent"
          label="Annual Rent (£)"
          type="number"
          isCurrency
          min={0}
          required
          defaultValue={toCurrencyValue(financial?.annualRent)}
        />
        <FormInput
          name="effectiveDate"
          label="Effective Date"
          type="date"
          required
          defaultValue={toDateForInput(financial?.effectiveDate)}
        />
      </div>
      <FormCheck
        name="isMandatory"
        label="Mandatory (Financials marked mandatory will be editable, not deletable)"
        defaultChecked={financial?.isMandatory ?? false}
      />
    </Modal>
  ) : null;
}
