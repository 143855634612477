import { useEffect, useState } from "react";
import {
  Property,
  PropertyPledgeStatus,
  PropertyStatus,
} from "../../../__generated__/graphql";
import { FormInput } from "../../../components/form/input";
import { FormSection } from "../../../components/form/section";
import { toCurrencyValue, toDateForInput } from "../../../utils/string.utils";

interface Props {
  property?: Property;
  manualFunded: number | null;
  setManualFunded: (value: number | null) => void;
}

export function PropertyFormAgreementSection({
  property,
  manualFunded,
  setManualFunded,
}: Props) {
  const [fundedPercentage, setFundedPercentage] = useState<string>("0");
  const [isAutomatic, setIsAutomatic] = useState<boolean>(true);
  const initialManualFunded = property?.manualFunded || null;
  const [status, setStatus] = useState<PropertyStatus>(
    property?.status || PropertyStatus.Pending || null
  );

  useEffect(() => {
    if (property) {
      setStatus(property.status);
      setManualFunded(property.manualFunded);
    }
  }, [property]);

  useEffect(() => {
    setIsAutomatic(manualFunded === null);
  }, [manualFunded]);

  const calculateFundedPercentage = (property: any) => {
    const confirmedPledges = property?.pledges?.filter(
      (pledge: any) => pledge.status === PropertyPledgeStatus.Confirmed
    );
    const totalConfirmedAmount = confirmedPledges?.reduce(
      (sum: any, pledge: any) => sum + pledge.amount,
      0
    );
    return ((totalConfirmedAmount / property?.price) * 100).toFixed(2);
  };

  useEffect(() => {
    if (property) {
      if (isAutomatic) {
        const calculatedPercentage = calculateFundedPercentage(property);
        setFundedPercentage(calculatedPercentage);
        setManualFunded(null);
      } else if (manualFunded !== null) {
        setFundedPercentage((manualFunded / 100).toString());
      }
    }
  }, [property, isAutomatic, manualFunded, setManualFunded]);

  const handleAutomaticToggle = () => {
    if (isAutomatic) {
      setIsAutomatic(false);
      setFundedPercentage(
        initialManualFunded ? (initialManualFunded / 100).toFixed(2) : "0"
      );
    } else {
      setIsAutomatic(true);
      setManualFunded(null);
      setFundedPercentage(calculateFundedPercentage(property));
    }
  };

  const handleFundedPercentageChange = (value: string) => {
    setFundedPercentage(value);

    if (!isAutomatic) {
      setManualFunded(
        value ? parseFloat(parseFloat(value).toFixed(2)) * 100 : 0
      );
    }
  };

  return (
    <FormSection
      title="Agreement"
      description="Details about the agreement with the property owner"
    >
      <div className="flex flex-col md:flex-row md:gap-3">
        <FormInput
          label="Agreed Price"
          containerClassname="flex-1"
          name="agreementPrice"
          type="number"
          step={1000}
          min={
            status === PropertyStatus.Pending
              ? 0
              : property?.agreementPrice / 100
          }
          required
          defaultValue={
            property ? toCurrencyValue(property?.agreementPrice) : ""
          }
          isCurrency
        />
        <FormInput
          label="Funding Deadline"
          containerClassname="flex-1"
          name="fundingDeadlineDate"
          type="date"
          required
          defaultValue={
            property ? toDateForInput(property.fundingDeadlineDate) : ""
          }
        />
      </div>
      <div className="flex flex-col md:flex-row md:gap-3">
        <FormInput
          label="Transaction Fee"
          containerClassname="flex-1"
          name="transactionFee"
          type="number"
          step={10}
          min={0}
          required
          defaultValue={
            property ? toCurrencyValue(property?.transactionFee) : ""
          }
          isCurrency
        />
        <FormInput
          label="Service Charges"
          containerClassname="flex-1"
          name="serviceCharges"
          type="number"
          min={0}
          step={10}
          required
          defaultValue={
            property ? toCurrencyValue(property?.serviceCharges) : ""
          }
          isCurrency
        />
      </div>
      <div className="flex flex-col md:flex-row md:gap-3">
        <FormInput
          data-test="min-investment-input"
          label="Minimum Investment (tokens)"
          containerClassname="flex-1"
          name="minimumInvestment"
          type="number"
          step={1}
          required
          defaultValue={property?.minimumInvestment}
          tokenToPound
          min={1}
        />
      </div>
      <div className="flex items-center justify-center flex-1 gap-4 mb-4">
        <div className={`flex flex-col flex-1`}>
          <label
            className="mb-2 text-sm font-semibold"
            htmlFor="funded_percentage"
          >
            Funded Percentage
          </label>
          <div className="relative">
            <p className="absolute text-sm top-3 text-slate-500 right-8">
              {isAutomatic ? "Automatic" : "Manual"}
            </p>
            <input
              className="w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-pn-blue focus:border-pn-blue block p-2.5"
              id="funded_percentage"
              name="fundedPercentage"
              type="number"
              step={0.01}
              value={fundedPercentage}
              disabled={isAutomatic}
              min={0}
              onChange={(e) =>
                handleFundedPercentageChange(e.currentTarget.value)
              }
            />
          </div>
        </div>
        <div className="flex items-center mb-2">
          <input
            type="checkbox"
            id="automaticPercentage"
            className="mr-2"
            checked={isAutomatic}
            onChange={handleAutomaticToggle}
          />
          <label htmlFor="automaticPercentage" className="text-sm">
            Automatic
          </label>
        </div>
      </div>
      {status === PropertyStatus.Completed && (
        <div className="flex md:flex-row md:gap-3">
          <p className="text-sm text-bold text-pn-blue top-3 right-8 mb-4 mt-1 text-left self-center">
            Funded in
          </p>
          <FormInput
            data-test="manual-funded-days-input"
            label=""
            containerClassname="flex-1"
            name="manualFundedDays"
            type="number"
            step={1}
            defaultValue={property?.manualFundedDays}
            // min={1}
            isDays
          />
        </div>
      )}
    </FormSection>
  );
}
