import { useEffect, useState } from "react";
import { FormSection } from "../../../components/form/section";
import { FormSelect } from "../../../components/form/select-type";
import { FormInput } from "../../../components/form/input";
import {
  Campaign,
  CampaignInvestorStatus,
  CampaignStatus,
} from "../../../__generated__/graphql";

interface Props {
  campaign?: Campaign;
}

const formatDateTimeLocal = (isoString?: string) => {
  if (!isoString) return "";
  const date = new Date(isoString);
  return date.toISOString().slice(0, 16);
};

const CampaignFormCriteriaSection = ({ campaign }: Props) => {
  const [investorFilter, setInvestorFilter] = useState<CampaignInvestorStatus>(
    campaign?.investorStatus || CampaignInvestorStatus.All
  );
  const [signupStart, setSignupStart] = useState<string>(
    campaign?.filterStartDate || ""
  );
  const [signupEnd, setSignupEnd] = useState<string>(
    campaign?.filterEndDate || ""
  );

  const investorStautsOptions = [
    { value: CampaignInvestorStatus.All, label: "All Investors" },
    { value: CampaignInvestorStatus.Unverified, label: "Unverified" },
    {
      value: CampaignInvestorStatus.Verified,
      label: "Verified but not Deposited",
    },
    {
      value: CampaignInvestorStatus.Deposited,
      label: "Deposited but not Invested",
    },
    { value: CampaignInvestorStatus.Invested, label: "Invested" },
    { value: CampaignInvestorStatus.Pending, label: "Has Pending Balance" },
  ];

  useEffect(() => {
    if (campaign) {
      setInvestorFilter(campaign.investorStatus);
      setSignupStart(formatDateTimeLocal(campaign.filterStartDate));
      setSignupEnd(formatDateTimeLocal(campaign.filterEndDate));
    }
  }, [campaign]);

  return (
    <FormSection
      title="Investor Selection Criteria"
      description="Filter and select investors for this campaign"
    >
      <FormSelect
        label="Filter Investors by Status"
        name="investorStatus"
        options={investorStautsOptions}
        value={investorFilter}
        onChange={(e) =>
          setInvestorFilter(
            (e.target as HTMLSelectElement).value as CampaignInvestorStatus
          )
        }
        disabled={campaign?.status === CampaignStatus.Sent}
      />
      {investorFilter !== CampaignInvestorStatus.All && (
        <>
          <FormInput
            label="Filter by Signup Date (Start)"
            name="signupStart"
            type="datetime-local"
            defaultValue={signupStart}
            onChange={(e) =>
              setSignupStart((e.target as HTMLInputElement).value)
            }
            disabled={campaign?.status === CampaignStatus.Sent}
          />
          <FormInput
            label="Filter by Signup Date (End)"
            name="signupEnd"
            type="datetime-local"
            defaultValue={signupEnd}
            onChange={(e) => setSignupEnd((e.target as HTMLInputElement).value)}
            disabled={campaign?.status === CampaignStatus.Sent}
          />
        </>
      )}
    </FormSection>
  );
};

export default CampaignFormCriteriaSection;
