import { useEffect, useState } from "react";
import { Property, PropertyStatus } from "../../../__generated__/graphql";
import { FormSection } from "../../../components/form/section";

interface Props {
  property?: Property;
  mode: "add" | "edit";
  loading?: boolean;
}

export function PropertyFormStatusSection({ property, mode, loading }: Props) {
  const [selectedStatus, setSelectedStatus] = useState<PropertyStatus>(
    property?.status || PropertyStatus.Pending
  );
  const [currentStatus, setCurrentStatus] = useState<PropertyStatus>(
    property?.status || PropertyStatus.Pending || null
  );

  useEffect(() => {
    if (property) {
      setSelectedStatus(property.status);
      setCurrentStatus(property.status);
    }
  }, [property]);

  if (selectedStatus === PropertyStatus.Completed) {
    return (
      <div className="p-6 rounded-lg bg-green-100 text-green-900 border border-green-200  mb-10">
        <h2 className="text-lg font-bold">Status Locked</h2>
        <p className="mt-2">
          The property is <span className="font-bold">{selectedStatus}</span>.
          Its status cannot be changed.
        </p>
      </div>
    );
  }

  return (
    <FormSection
      title="Status"
      description="Status of the property. Only active properties will displayed to investors"
    >
      <ul
        className="space-y-1 text-sm text-gray-700"
        aria-labelledby="dropdownHelperRadioButton"
      >
        {currentStatus === PropertyStatus.Pending && (
          <li>
            <div className="flex px-4 py-2 cursor-pointer rounded-lg hover:bg-gray-100">
              <div className="flex items-center h-5">
                <input
                  id="pending"
                  name="status"
                  type="radio"
                  disabled={loading}
                  value={PropertyStatus.Pending}
                  checked={selectedStatus === PropertyStatus.Pending}
                  className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                  onChange={(e) =>
                    setSelectedStatus(e.currentTarget.value as PropertyStatus)
                  }
                />
              </div>
              <div className="ms-2 text-sm">
                <label
                  htmlFor="pending"
                  className="cursor-pointer font-medium text-gray-900"
                >
                  <div>Pending</div>
                  <p
                    id="helper-radio-text"
                    className="text-xs font-normal text-gray-500"
                  >
                    Property has been newly added and incomplete
                  </p>
                </label>
              </div>
            </div>
          </li>
        )}
        <li>
          <div className="flex px-4 py-2 cursor-pointer rounded-lg hover:bg-gray-100">
            <div className="flex items-center h-5">
              <input
                id="inactive"
                name="status"
                type="radio"
                disabled={loading || currentStatus === PropertyStatus.Pending}
                value={PropertyStatus.Inactive}
                checked={selectedStatus === PropertyStatus.Inactive}
                className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                onChange={(e) =>
                  setSelectedStatus(e.currentTarget.value as PropertyStatus)
                }
              />
            </div>
            <div className="ms-2 text-sm">
              <label
                htmlFor="inactive"
                className="cursor-pointer font-medium text-gray-900"
              >
                <div>Inactive</div>
                <p
                  id="helper-radio-text-4"
                  className="text-xs font-normal text-gray-500"
                >
                  Property details are complete, but don't display to investors
                  yet
                </p>
                {!loading && currentStatus === PropertyStatus.Pending && (
                  <p
                    id="helper-radio-text-4"
                    className="text-xs font-normal text-red"
                  >
                    Can be activated once property is marked as Active
                  </p>
                )}
              </label>
            </div>
          </div>
        </li>
        <li>
          <div className="flex px-4 py-2 cursor-pointer rounded-lg hover:bg-gray-100">
            <div className="flex items-center h-5">
              <input
                disabled={mode === "add" || loading}
                id="active"
                name="status"
                type="radio"
                value={PropertyStatus.Active}
                checked={selectedStatus === PropertyStatus.Active}
                className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                onChange={(e) =>
                  setSelectedStatus(e.currentTarget.value as PropertyStatus)
                }
              />
            </div>
            <div className="ms-2 text-sm">
              <label
                htmlFor="active"
                className="cursor-pointer font-medium text-gray-900"
              >
                <div>Active</div>
                <p
                  id="helper-radio-text-4"
                  className="text-xs font-normal text-gray-500"
                >
                  Property is ready to be displayed to investors
                </p>
                {!loading && mode === "add" && (
                  <p
                    id="helper-radio-text-4"
                    className="text-xs font-normal text-red"
                  >
                    Can be activated once details complete, and 3 images, 1
                    financial, 1 rent added
                  </p>
                )}
              </label>
            </div>
          </div>
        </li>
        <li>
          <div className="flex px-4 py-2 cursor-pointer rounded-lg hover:bg-gray-100">
            <div className="flex items-center h-5">
              <input
                disabled={
                  mode === "add" ||
                  currentStatus === PropertyStatus.Pending ||
                  loading
                }
                id="coming_soon"
                name="status"
                type="radio"
                value={PropertyStatus.ComingSoon}
                checked={selectedStatus === PropertyStatus.ComingSoon}
                className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                onChange={(e) =>
                  setSelectedStatus(e.currentTarget.value as PropertyStatus)
                }
              />
            </div>
            <div className="ms-2 text-sm">
              <label
                htmlFor="coming_soon"
                className="cursor-pointer font-medium text-gray-900"
              >
                <div>Coming Soon</div>
                <p
                  id="helper-radio-text-4"
                  className="text-xs font-normal text-gray-500"
                >
                  Property will not show any details except a coming soon
                  message
                </p>
                {!loading && mode === "add" && (
                  <p
                    id="helper-radio-text-4"
                    className="text-xs font-normal text-red"
                  >
                    Can be activated once details complete, and 3 images, 1
                    financial, 1 rent added
                  </p>
                )}
                {!loading &&
                  mode === "edit" &&
                  currentStatus === PropertyStatus.Pending && (
                    <p
                      id="helper-radio-text-4"
                      className="text-xs font-normal text-red"
                    >
                      Can be activated once property is marked as Active
                    </p>
                  )}
              </label>
            </div>
          </div>
        </li>
      </ul>
    </FormSection>
  );
}
