import { useEffect, useState } from "react";
import { Campaign, CampaignStatus } from "../../../__generated__/graphql";
import { FormSection } from "../../../components/form/section";

interface Props {
  campaign?: Campaign;
  mode: "add" | "edit";
}

export function CampaignFormStatusSection({ campaign, mode }: Props) {
  const [isActive, setIsActive] = useState<boolean>(!!campaign?.isActive);

  useEffect(() => {
    if (campaign?.isActive !== undefined) {
      setIsActive(campaign.isActive);
    }
  }, [campaign?.isActive]);

  return (
    <FormSection
      title="Status"
      description="Status of the campaign. Only active campaigns will send notifications"
    >
      <ul
        className="space-y-1 text-sm text-gray-700"
        aria-labelledby="dropdownHelperRadioButton"
      >
        {/* Inactive Option */}
        <li>
          <div className="flex px-4 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
            <div className="flex items-center h-5">
              <input
                id="inactive"
                name="status"
                type="radio"
                value="false"
                checked={!isActive}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer focus:ring-blue-500"
                onChange={() => setIsActive(false)}
                disabled={campaign?.status === CampaignStatus.Sent}
              />
            </div>
            <div className="text-sm ms-2">
              <label
                htmlFor="inactive"
                className="font-medium text-gray-900 cursor-pointer"
              >
                <div>Inactive</div>
                <p className="text-xs font-normal text-gray-500">
                  This campaign is currently inactive.
                </p>
              </label>
            </div>
          </div>
        </li>

        {/* Active Option */}
        <li>
          <div className="flex px-4 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
            <div className="flex items-center h-5">
              <input
                id="active"
                name="status"
                type="radio"
                value="true"
                checked={isActive}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer focus:ring-blue-500"
                onChange={() => setIsActive(true)}
                disabled={
                  mode === "add" ||
                  campaign?.status === CampaignStatus.Sent ||
                  (campaign?.status === CampaignStatus.Expired &&
                    campaign?.scheduledAt < new Date().toISOString())
                }
              />
            </div>
            <div className="text-sm ms-2">
              <label
                htmlFor="active"
                className="font-medium text-gray-900 cursor-pointer"
              >
                <div>Active</div>
                <p className="text-xs font-normal text-gray-500">
                  The campaign is active and will send notifications.
                </p>
              </label>

              {mode === "add" && (
                <p className="text-xs font-normal text-red-500">
                  Activation is only possible after campaign creation.
                </p>
              )}

              {campaign?.status === CampaignStatus.Expired &&
                campaign?.scheduledAt < new Date().toISOString() && (
                  <p className="text-xs font-normal text-red-500">
                    This campaign has expired. Update the scheduled time to
                    reactivate.
                  </p>
                )}
            </div>
          </div>
        </li>
      </ul>
    </FormSection>
  );
}
