import { Link } from "react-router-dom";
import { AppRoute } from "../../../routes";
import { ApolloError } from "@apollo/client";

interface Props {
  submitting: boolean;
  updating: boolean;
  added: boolean;
  updated: boolean;
  completed: boolean;
  expired: boolean;
  submitError?: ApolloError;
  updateError?: ApolloError;
}

export function CampaignFormMessages(props: Props) {
  return (
    <>
      {props.submitting && (
        <div className="">
          <p className="text-sm text-slate-400">
            Adding campaign. Please wait...
          </p>
        </div>
      )}
      {props.updating && (
        <div className="">
          <p className="text-sm text-slate-400">
            Updating campaign. Please wait...
          </p>
        </div>
      )}
      {props.submitError && (
        <div className="flex px-4 py-2 mb-4 rounded shadow bg-amber-100">
          <p className="text-sm font-semibold text-red">
            {props.submitError.message}
          </p>
        </div>
      )}
      {props.updateError && (
        <div className="flex px-4 py-2 mb-4 rounded shadow bg-amber-100">
          <p className="text-sm font-semibold text-red">
            {props.updateError.message}
          </p>
        </div>
      )}
      {props.expired && (
        <div className="flex items-center justify-between px-4 py-2 mb-4 bg-red-100 rounded shadow">
          <p className="text-sm font-semibold text-red">
            Campaign is expired. Kindly update the schedule.
          </p>
        </div>
      )}
      {props.added && (
        <div className="flex items-center justify-between px-4 py-2 mb-4 bg-green-100 rounded shadow">
          <p className="text-sm font-semibold">Campaign added successfully</p>
          <Link
            className="text-xs text-pn-blue hover:underline"
            to={AppRoute.CampaignList}
          >
            Back to Campaigns
          </Link>
        </div>
      )}
      {props.updated && (
        <div className="flex items-center justify-between px-4 py-2 mb-4 bg-green-100 rounded shadow">
          <p className="mb-1 text-sm font-semibold">
            Campaign saved successfully
          </p>
          <Link
            className="text-xs text-pn-blue hover:underline"
            to={AppRoute.CampaignList}
          >
            Back to Campaigns
          </Link>
        </div>
      )}
      {props.completed && (
        <div className="flex items-center justify-between px-4 py-2 mb-4 bg-green-100 rounded shadow">
          <p className="mb-1 text-sm font-semibold">
            Campaign is already over.
          </p>
          <Link
            className="text-xs text-pn-blue hover:underline"
            to={AppRoute.CampaignList}
          >
            Back to Campaigns
          </Link>
        </div>
      )}
    </>
  );
}
