import { gql } from "@apollo/client";

export const MUTATION_ADD_FINANCIAL = gql`
  mutation addFinancial(
    $propertyId: String!
    $projectedAnnualRoIPercentage: Int!
    $projectedAnnualRoIValue: BigInt!
    $year2ProjectedAnnualRoIPercentage: Int!
    $year2ProjectedAnnualRoIValue: BigInt!
    $year3ProjectedAnnualRoIPercentage: Int!
    $year3ProjectedAnnualRoIValue: BigInt!
    $annualRent: BigInt!
    $effectiveDate: String!
    $isMandatory: Boolean!
  ) {
    addFinancial(
      data: {
        propertyId: $propertyId
        projectedAnnualRoIPercentage: $projectedAnnualRoIPercentage
        projectedAnnualRoIValue: $projectedAnnualRoIValue
        year2ProjectedAnnualRoIPercentage: $year2ProjectedAnnualRoIPercentage
        year2ProjectedAnnualRoIValue: $year2ProjectedAnnualRoIValue
        year3ProjectedAnnualRoIPercentage: $year3ProjectedAnnualRoIPercentage
        year3ProjectedAnnualRoIValue: $year3ProjectedAnnualRoIValue
        annualRent: $annualRent
        effectiveDate: $effectiveDate
        isMandatory: $isMandatory
      }
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_FINANCIAL = gql`
  mutation updateFinancial(
    $id: String!
    $propertyId: String!
    $projectedAnnualRoIPercentage: Int!
    $projectedAnnualRoIValue: BigInt!
    $year2ProjectedAnnualRoIPercentage: Int!
    $year2ProjectedAnnualRoIValue: BigInt!
    $year3ProjectedAnnualRoIPercentage: Int!
    $year3ProjectedAnnualRoIValue: BigInt!
    $annualRent: BigInt!
    $effectiveDate: String!
    $isMandatory: Boolean!
  ) {
    updateFinancial(
      data: {
        id: $id
        propertyId: $propertyId
        projectedAnnualRoIPercentage: $projectedAnnualRoIPercentage
        projectedAnnualRoIValue: $projectedAnnualRoIValue
        year2ProjectedAnnualRoIPercentage: $year2ProjectedAnnualRoIPercentage
        year2ProjectedAnnualRoIValue: $year2ProjectedAnnualRoIValue
        year3ProjectedAnnualRoIPercentage: $year3ProjectedAnnualRoIPercentage
        year3ProjectedAnnualRoIValue: $year3ProjectedAnnualRoIValue
        annualRent: $annualRent
        effectiveDate: $effectiveDate
        isMandatory: $isMandatory
      }
    ) {
      id
    }
  }
`;

export const MUTATION_DELETE_FINANCIAL = gql`
  mutation deleteFinancial($id: String!) {
    deleteFinancial(data: { id: $id })
  }
`;
