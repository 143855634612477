import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { AccountLoginPage } from "./pages/account/login/login";
import { AccountForgetPage } from "./pages/account/forget/forget";
import { AccountResetPage } from "./pages/account/reset/reset";
import { AdminPage } from "./pages/admins/admin";
import { AdminListPage } from "./pages/admins/admin_list";
import { InvestorListPage } from "./pages/investors/investor_list";
import { PropertyEditPage } from "./pages/properties/property";
import { PropertyListPage } from "./pages/properties/property_list";
import { PropertyPage } from "./pages/property/property";
import { RewardsPage } from "./pages/rewards/rewards";
import { RoleListPage } from "./pages/roles/roles_list";
import { RolePage } from "./pages/roles/role";
import { AnalyticsPage } from "./pages/analytics/analytics";
import { LeadListPage } from "./pages/leads/lead_list";
import { CitiesPage } from "./pages/cities/cities";
import { Error404Page } from "./pages/error/error404";
import { OtpPage } from "./pages/account/otp/otp";
import { CmsPage } from "./pages/cms/cms";
import Inverstor from "./pages/investors/inverstor";
import { TransactionListPage } from "./pages/transactions/transaction_list";
import { WelcomePage } from "./pages/welcome/welcome";
import { ProtectedRoute } from "./components/protected-route/protected-route";
import { RoleModule, RoleAction } from "./__generated__/graphql";
import { ReferralListPage } from "./pages/referrals/referral_list";
import { ReferralSourcePage } from "./pages/referrals/referral_source";
import CampaignListPage from "./pages/campaigns/campaign_list";
import { CampaignPage } from "./pages/campaigns/campaign";

export enum AppRoute {
  Home = "/",
  Welcome = "/welcome",
  Analytics = "/analytics",
  PropertyList = "/property/list",
  PropertyEdit = "/property/edit",
  Property = "/property",
  Investors = "/investor/list",
  Investor = "/investor",
  InvestorProfile = "/investor/:id",
  Transactions = "/transaction/list",
  LeadList = "/lead/list",
  AdminList = "/admin/list",
  Admin = "/admin",
  RoleList = "/role/list",
  Role = "/role",
  ReferralList = "/referral/list",
  ReferralSource = "/referral/source",
  Rewards = "/rewards",
  Config = "/config",
  AccountLogin = "/account/login",
  Cities = "/cities",
  AccountReset = "/account/reset",
  AccountForget = "/account/forget",
  LoginVefitication = "/account/verify",
  Cms = "/cms",
  CampaignList = "/campaign/list",
  Campaign = "/campaign",
}

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route
      path={AppRoute.Home}
      element={<Navigate to={AppRoute.PropertyList} />}
    />,
    <Route path={AppRoute.Welcome} element={<WelcomePage />} />,
    <Route
      path={AppRoute.Analytics}
      element={
        <ProtectedRoute
          module={RoleModule.Analytics}
          actions={[RoleAction.View]}
          element={<AnalyticsPage />}
        />
      }
    />,
    <Route
      path={AppRoute.PropertyList}
      element={
        <ProtectedRoute
          module={RoleModule.Property}
          actions={[RoleAction.List]}
          element={<PropertyListPage />}
        />
      }
    />,
    <Route
      path={AppRoute.PropertyEdit}
      element={
        <ProtectedRoute
          module={RoleModule.Property}
          actions={[RoleAction.Update]}
          element={<PropertyEditPage />}
        />
      }
    />,
    <Route
      path={AppRoute.Property}
      element={
        <ProtectedRoute
          module={RoleModule.Property}
          actions={[RoleAction.View]}
          element={<PropertyPage />}
        />
      }
    />,
    <Route
      path={AppRoute.Investors}
      element={
        <ProtectedRoute
          module={RoleModule.Investor}
          actions={[RoleAction.List]}
          element={<InvestorListPage />}
        />
      }
    />,
    <Route
      path={AppRoute.Investor}
      element={
        <ProtectedRoute
          module={RoleModule.Investor}
          actions={[RoleAction.List]}
          element={<InvestorListPage />}
        />
      }
    />,
    <Route
      path={AppRoute.InvestorProfile}
      element={
        <ProtectedRoute
          module={RoleModule.Investor}
          actions={[RoleAction.List, RoleAction.View]}
          element={<Inverstor />}
        />
      }
    />,
    <Route
      path={AppRoute.Transactions}
      element={
        <ProtectedRoute
          module={RoleModule.Transaction}
          actions={[RoleAction.List]}
          element={<TransactionListPage />}
        />
      }
    />,
    <Route
      path={AppRoute.LeadList}
      element={
        <ProtectedRoute
          module={RoleModule.Leads}
          actions={[RoleAction.List]}
          element={<LeadListPage />}
        />
      }
    />,
    <Route
      path={AppRoute.AdminList}
      element={
        <ProtectedRoute
          module={RoleModule.Admin}
          actions={[RoleAction.List]}
          element={<AdminListPage />}
        />
      }
    />,
    <Route
      path={AppRoute.Admin}
      element={
        <ProtectedRoute
          module={RoleModule.Admin}
          actions={[RoleAction.View, RoleAction.Update]}
          element={<AdminPage />}
        />
      }
    />,
    <Route
      path={AppRoute.RoleList}
      element={
        <ProtectedRoute
          module={RoleModule.Role}
          actions={[RoleAction.List]}
          element={<RoleListPage />}
        />
      }
    />,
    <Route path={AppRoute.Role} element={<RolePage />} />,
    <Route
      path={AppRoute.Rewards}
      element={
        <ProtectedRoute
          module={RoleModule.Rewards}
          actions={[RoleAction.List]}
          element={<RewardsPage />}
        />
      }
    />,
    <Route path={AppRoute.AdminList} element={<RewardsPage />} />,
    <Route path={AppRoute.Config} element={<RewardsPage />} />,
    <Route path={AppRoute.AccountLogin} element={<AccountLoginPage />} />,
    <Route path={AppRoute.AccountForget} element={<AccountForgetPage />} />,
    <Route path={AppRoute.AccountReset} element={<AccountResetPage />} />,
    <Route
      path={`${AppRoute.ReferralSource}/:id`}
      element={
        <ProtectedRoute
          module={RoleModule.Referral}
          actions={[RoleAction.View, RoleAction.Update, RoleAction.Add]}
          element={<ReferralSourcePage />}
        />
      }
    />,
    <Route
      path={AppRoute.ReferralList}
      element={
        <ProtectedRoute
          module={RoleModule.Referral}
          actions={[
            RoleAction.View,
            RoleAction.Update,
            RoleAction.Delete,
            RoleAction.Add,
          ]}
          element={<ReferralListPage />}
        />
      }
    />,
    <Route
      path={AppRoute.Cities}
      element={
        <ProtectedRoute
          module={RoleModule.Cities}
          actions={[RoleAction.List]}
          element={<CitiesPage />}
        />
      }
    />,
    <Route
      path={AppRoute.Cms}
      element={
        <ProtectedRoute
          module={RoleModule.Cms}
          actions={[RoleAction.Update]}
          element={<CmsPage />}
        />
      }
    />,
    <Route
      path={AppRoute.CampaignList}
      element={
        <ProtectedRoute
          module={RoleModule.Campaign}
          actions={[RoleAction.List]}
          element={<CampaignListPage />}
        />
      }
    />,
    <Route
      path={AppRoute.Campaign}
      element={
        <ProtectedRoute
          module={RoleModule.Campaign}
          actions={[RoleAction.Add, RoleAction.Update]}
          element={<CampaignPage />}
        />
      }
    />,
    <Route path={AppRoute.LoginVefitication} element={<OtpPage />} />,
    <Route path="*" element={<Error404Page />} />,
  ])
);

export const AppRoutes = () => <RouterProvider router={router} />;
