import { useLazyQuery, useMutation } from "@apollo/client";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Role, RoleAction, RoleModule } from "../../__generated__/graphql";
import { FormButton } from "../../components/form/button";
import { LoadingPlaceholder } from "../../components/loading/placeholder";
import { Sidebar } from "../../components/sidebar/sidebar";
import { Tab } from "../../components/tab/tab";
import { QUERY_ROLES } from "../../graphql/queries/role";
import { AppRoute } from "../../routes";
import { AuthService } from "../../services/auth.service";
import { MUTATION_DELETE_ROLE } from "../../graphql/mutations/role";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";

export function RoleListPage() {
  const navigate = useNavigate();

  const [getRoles, { loading, error, data }] = useLazyQuery(QUERY_ROLES, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  });
  const [
    deleteRole,
    { loading: deleteLoading, error: deleteError, data: deleteData },
  ] = useMutation(MUTATION_DELETE_ROLE, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  });
  const [selectedTabItem, setSelectedTabItem] = useState("All");
  const [selectedRow, setSelectedRow] = useState<number>();

  useAuthAndErrorHandling(error);

  useEffect(() => {
    if (!AuthService.authenticate(navigate)) {
      return;
    }

    getRoles({
      variables: {
        take: 10,
        skip: 0,
        where: { filters: getFiltersForTabItem(selectedTabItem) },
      },
    });
  }, []);

  // Navigate table using up, down and enter
  useEffect(() => {
    const handleKeyNav = (event: KeyboardEvent) => {
      if (!data) {
        return;
      }

      switch (event.key) {
        case "Enter":
          if (
            selectedRow !== undefined &&
            selectedRow >= 0 &&
            selectedRow < data.properties.length
          ) {
            selectRole(data.properties[selectedRow].id);
          }
          break;
        case "ArrowUp":
          if (selectedRow !== undefined && selectedRow > 0) {
            setSelectedRow(selectedRow - 1);
          } else {
            setSelectedRow(data.properties.length - 1);
          }
          break;
        case "ArrowDown":
          if (
            selectedRow !== undefined &&
            selectedRow < data.properties.length - 1
          ) {
            setSelectedRow(selectedRow + 1);
          } else {
            setSelectedRow(0);
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyNav);

    return () => {
      window.removeEventListener("keydown", handleKeyNav);
    };
  }, [data, selectedRow, selectRole]);

  useEffect(() => {
    if (deleteData) {
      getRoles({
        variables: {
          take: 30,
          skip: 0,
          where: { filters: getFiltersForTabItem(selectedTabItem) },
        },
      });
    }
  }, [deleteData]);

  useEffect(() => {
    getRoles({
      variables: {
        take: 30,
        skip: 0,
        where: { filters: getFiltersForTabItem(selectedTabItem) },
      },
    });
  }, [selectedTabItem]);

  function handleTabItemClick(tabItem: string) {
    setSelectedTabItem(tabItem);
  }

  function getFiltersForTabItem(tabItem: string) {
    switch (tabItem) {
      case "All":
        return [];
      default:
        return {};
    }
  }

  function selectRole(id: string) {
    navigate(`${AppRoute.Role}?id=${id}`);
  }

  return (
    <>
      <Sidebar />

      <section className="content full properties">
        <h1 className="mb-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Roles
        </h1>

        <div className="flex flex-col-reverse md:flex-row md:justify-between">
          <Tab
            items={["All"]}
            selectedTabItem={selectedTabItem}
            loading={loading}
            handleTabItemClick={handleTabItemClick}
          />
          {AuthService.hasRole(RoleModule.Role, RoleAction.Add) && (
            <div className="flex gap-2 mb-2 md:mb-0">
              <a
                href="#"
                className="flex flex-wrap content-center justify-center w-full h-12 gap-2 px-4 py-2 transition-all duration-300 rounded-lg mb:w-auto bg-pn-blue hover:bg-pn-dark-yellow"
                onClick={() => navigate(AppRoute.Role)}
              >
                <PlusCircleIcon width={24} className="text-white" />
                <span className="self-center text-sm font-semibold text-white">
                  Add Role
                </span>
              </a>
            </div>
          )}
        </div>

        <table className="w-full pt-2 bg-white border border-separate rounded-lg table-auto">
          <thead>
            <tr>
              <th className="px-4 py-3 border-b">Name</th>
              <th className="px-4 py-3 border-b">Assigned To</th>
              <th className="px-4 py-3 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              data &&
              data.roles &&
              data.roles.map((role: Role, index: number) => (
                <tr key={index}>
                  <td className="px-4 py-3 text-sm text-nowrap">{role.name}</td>
                  <td className="px-4 py-3 text-sm text-center">
                    {role.admins.length === 0
                      ? "No one"
                      : `${role.admins.length} admins`}
                  </td>
                  <td className="hidden float-right px-4 py-3 text-sm md:flex md:gap-2">
                    {AuthService.hasRole(
                      RoleModule.Role,
                      RoleAction.Update
                    ) && (
                      <FormButton
                        title="Update"
                        size="xs"
                        onClick={() => selectRole(role.id)}
                      />
                    )}
                    {AuthService.hasRole(
                      RoleModule.Role,
                      RoleAction.Delete
                    ) && (
                      <FormButton
                        title="Delete"
                        size="xs"
                        onClick={() =>
                          deleteRole({ variables: { id: role.id } })
                        }
                      />
                    )}
                  </td>
                </tr>
              ))}
            {loading && (
              <tr>
                <td colSpan={5} className="text-center">
                  <LoadingPlaceholder />
                </td>
              </tr>
            )}
            {!loading && (!data || !data.roles || data.roles.length === 0) && (
              <tr>
                <td colSpan={5} className="text-sm text-center">
                  <div className="flex flex-col items-center gap-2 p-5">
                    <InformationCircleIcon width={60} />
                    <p className="font-bold">No {selectedTabItem} Roles</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
    </>
  );
}
